import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { config } from '@abyss/web/tools/config';
import { Button } from '@abyss/web/ui/Button';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import find from 'lodash/find';
import { useTranslation } from 'react-i18next';

import { useCurrentMemberIsPreEffective } from '../../hooks/useCurrentMemberIsPreEffective';
import { useLagoon } from '../../hooks/useLagoon';
import { usePrimaryCare } from '../../hooks/usePrimaryCare';
import {
  getCurrentMember,
  getCurrentMemberPCPInfo,
  getLoggedInMember,
} from '../../utils/user.utils';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import { isEniMember } from '../Utils/memberUtils/memberUtils';
import {
  MedicalAvatar,
  ModalContent,
  ModalDataContent,
  ModalHeading,
  ModalSubTitle,
  ThingsToConsiderMobileModalStyle,
  ThingsToConsiderMobileModalStyleEni,
  ThingsToConsiderModalStyle,
  ThingsToConsiderModalStyleEni,
} from './ThingsToConsider.style';

export const ThingsToConsiderModal = ({
  isOpen,
  onClose,
  onContinue,
  editPcpClicked,
}) => {
  if (!isOpen) return null;
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const uiMessaging = useLagoon('ui-messaging')();

  const changePCPTitle = find(uiMessaging, {
    key: ConstantsLagoon.THINGS_TO_CONSIDER.THINGS_TO_CONSIDER_TITLE,
  });
  const choosePCPTitle = find(uiMessaging, {
    key: ConstantsLagoon.THINGS_TO_CONSIDER.THINGS_TO_CONSIDER_TITLE_CHOOSE_PCP,
  });
  const thingsToConsiderSubTitle = find(uiMessaging, {
    key: ConstantsLagoon.THINGS_TO_CONSIDER.THINGS_TO_CONSIDER_SUBTITLE,
  });
  const thingsToConsiderSubTitleChoosePcp = find(uiMessaging, {
    key: ConstantsLagoon.THINGS_TO_CONSIDER
      .THINGS_TO_CONSIDER_SUBTITLE_CHOOSE_PCP,
  });

  const sectionOneTitle = find(uiMessaging, {
    key: ConstantsLagoon.THINGS_TO_CONSIDER
      .THINGS_TO_CONSIDER_SECTION_ONE_TITLE,
  });
  const sectionOneContent = find(uiMessaging, {
    key: ConstantsLagoon.THINGS_TO_CONSIDER
      .THINGS_TO_CONSIDER_SECTION_ONE_CONTENT,
  });

  const sectionTwoTitle = find(uiMessaging, {
    key: ConstantsLagoon.THINGS_TO_CONSIDER
      .THINGS_TO_CONSIDER_SECTION_TWO_TITLE,
  });
  const sectionTwoContent = find(uiMessaging, {
    key: ConstantsLagoon.THINGS_TO_CONSIDER
      .THINGS_TO_CONSIDER_SECTION_TWO_CONTENT,
  });

  const sectionThreeTitle = find(uiMessaging, {
    key: ConstantsLagoon.THINGS_TO_CONSIDER
      .THINGS_TO_CONSIDER_SECTION_THREE_TITLE,
  });
  const sectionThreeContent = find(uiMessaging, {
    key: ConstantsLagoon.THINGS_TO_CONSIDER
      .THINGS_TO_CONSIDER_SECTION_THREE_CONTENT,
  });
  const currentMember = getCurrentMember();
  const { lineOfBusiness } = currentMember;
  const isEni = isEniMember(lineOfBusiness);

  const { data } = usePrimaryCare();
  const { primaryCare } = data;
  const loggedInMemberInfo = getLoggedInMember();
  const dependentSeqNbr = loggedInMemberInfo?.demographics?.dependentSeqNbr;
  const futurePCPInfo = primaryCare?.find(
    ({ depSeqNbr }) => dependentSeqNbr === depSeqNbr
  )?.pcpInfo?.futurePCP;

  const isPreEffective = useCurrentMemberIsPreEffective();

  const memberPCPInfo = getCurrentMemberPCPInfo(
    dependentSeqNbr,
    primaryCare,
    isPreEffective
  );

  return (
    <Modal
      // @ts-ignore
      css={
        mobileScreen
          ? isEni
            ? ThingsToConsiderMobileModalStyleEni
            : ThingsToConsiderMobileModalStyle
          : isEni
          ? ThingsToConsiderModalStyleEni
          : ThingsToConsiderModalStyle
      }
      data-testid="things-to-consider-modal"
      footer={
        <Modal.Section>
          <Layout.Group alignLayout="right">
            <Button
              data-testid="cancel-button"
              onClick={onClose}
              variant="outline"
            >
              {t('THINGS_TO_CONSIDER_MODAL.CANCEL_BUTTON')}
            </Button>
            <Button data-testid="continue-button" onClick={onContinue}>
              {t('THINGS_TO_CONSIDER_MODAL.CONTINUE_BUTTON')}
            </Button>
          </Layout.Group>
        </Modal.Section>
      }
      isOpen={isOpen}
      onClose={onClose}
      title={
        memberPCPInfo || futurePCPInfo || editPcpClicked
          ? changePCPTitle?.message
          : loggedInMemberInfo.editPcp
          ? choosePCPTitle?.message
          : ''
      }
      titleAlign="center"
    >
      <ModalContent data-testid="things-to-consider-content">
        <MedicalAvatar style={mobileScreen ? { left: '40%' } : { left: '45%' }}>
          <img
            alt=""
            src={`${config(
              'CDN_BASE_URL'
            )}/cdn/assets/images/default_Practitioner.jpg`}
          />
        </MedicalAvatar>
        <Modal.Section data-testid="things-to-consider-section">
          <ModalSubTitle
            style={
              isEni && mobileScreen
                ? {
                    overflow: 'hidden',
                    width: '100%',
                    fontSize: '16px',
                    marginBottom: '16px',
                  }
                : isEni
                ? { fontSize: '16px', width: '122%', marginBottom: '16px' }
                : {}
            }
          >
            {memberPCPInfo || futurePCPInfo
              ? thingsToConsiderSubTitle?.message
              : loggedInMemberInfo.editPcp
              ? thingsToConsiderSubTitleChoosePcp?.message
              : ''}
          </ModalSubTitle>
          {sectionOneTitle?.message && (
            <ModalHeading
              style={
                isEni
                  ? {
                      fontSize: '16px',
                      margin: '0px',
                      paddingBottom: '8px',
                      lineHeight: '20px',
                    }
                  : {}
              }
            >
              {sectionOneTitle?.message}
            </ModalHeading>
          )}
          {sectionOneContent?.message && (
            <ModalDataContent
              style={
                isEni
                  ? {
                      fontSize: '16px',
                      margin: '0px',
                      marginBottom: '24px',
                      paddingBottom: '8px',
                      lineHeight: '20px',
                    }
                  : {}
              }
            >
              {sectionOneContent?.message}
            </ModalDataContent>
          )}
          {sectionTwoTitle?.message && (
            <ModalHeading
              style={
                isEni
                  ? {
                      fontSize: '16px',
                      margin: '0px',
                      paddingBottom: '8px',
                      lineHeight: '20px',
                    }
                  : {}
              }
            >
              {sectionTwoTitle?.message}
            </ModalHeading>
          )}
          {sectionTwoContent?.message && (
            <ModalDataContent
              style={
                isEni
                  ? {
                      fontSize: '16px',
                      margin: '0px',
                      marginBottom: '24px',
                      paddingBottom: '8px',
                      lineHeight: '20px',
                    }
                  : {}
              }
            >
              {sectionTwoContent?.message}
            </ModalDataContent>
          )}
          {sectionThreeTitle?.message && (
            <ModalHeading>{sectionThreeTitle?.message}</ModalHeading>
          )}
          {sectionThreeContent?.message && (
            <ModalDataContent>
              <div
                dangerouslySetInnerHTML={{
                  __html: sectionThreeContent?.message,
                }}
              />
            </ModalDataContent>
          )}
        </Modal.Section>
      </ModalContent>
    </Modal>
  );
};
