import { GuestPortalMemberInfo } from './GuestPortalMemberInfo';
import { PortalMemberInfo } from './PortalMemberInfo';

export interface PortalUrl {
  name: string;
  urlPath: string;
}
export interface PortalConfig {
  basePath: string;
  theme: string;
  enableSession?: boolean;
  graphQlUrl?: string;
  mapLocationUrl?: string;
  headerChipsEnabled?: boolean;
  homePage?: string;
  language?: string;
  isGuestUser?: boolean;
  portalUrls?: PortalUrl[];
  showCost?: boolean;
  showTreatmentCenter?: boolean;
  showOnlineScheduling?: boolean;
  includeBaseCss?: boolean;
  signInUrl?: string;
  userTier?: string;
  showEapFilter?: boolean;
  showMarketPlace?: boolean;
  lockEapFilter?: boolean;
  preSelectEapFilter?: boolean;
  accessCode?: string;
  legacySSOUrl?: string;
  showVirtualVisits?: boolean;
  themeOverride?: object;
  useOBAPI?: boolean;
  usePlanSelector?: boolean;
  loadAdobeScript?: boolean;
}

export enum PortalName {
  UHC = 'uhc',
  UMR = 'umr',
  STUDENT_RESOURCE = 'studentResources',
  ALL_SAVERS = 'allsavers',
  UHONE = 'uhone',
  LAWW = 'LAWW',
  ONE_PORTAL = 'onePortal',
}

export enum PortalIdentifier {
  STANDARD = 'standard',
  GEHA = 'geha',
  CUSTOM = 'custom',
  NV = 'NV',
}

export interface PortalEligibilityData {
  firstName: string;
  lastName: string;
  dob: string;
  memberId: string;
  policyId: string;
  delsysCode: string;
  enablePcp?: boolean;
  useObapiPreprod?: boolean;
  uniqueMemberId?: string;
  zipCode?: string;
  callingUser?: string;
}

export interface PortalData {
  portalName: PortalName;
  portalIdentifier?: string;
  portalConfig: PortalConfig;
  portalEligibilityData?: PortalEligibilityData;
  portalUrls?: PortalUrl[];
  portalMemberInfo?: PortalMemberInfo | GuestPortalMemberInfo;
}
