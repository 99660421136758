import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { Text } from '@abyss/web/ui/Text';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { returnSuppressFlag } from '../../utils/featureSuppress';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { FilterCollectionModel } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import { Distance } from '../ProviderDetailTabs/ProviderLocationsDetails/Distance';
import { DistanceInfoIcon } from './DistanceInfoIcon';
import { HealthGradesDisclaimer } from './Healthgrades';

type Radio = {
  // eslint-disable-next-line react/no-unused-prop-types
  label: String | ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  value: string | number | boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  dnt_label: string;
};

interface Props {
  content?: string;
  bestMatchTitle?: string;
  groupLabel?: string;
  options: Array<Radio>;
  defaultValue?: string | number | boolean;
  model: string;
  submitBtnName?: string;
  disableSubmitBtn?: boolean;
  onOptionChange?: (any) => void;
  onApply?: (value: string) => void;
  labelFontSize?: string;
  locationForAnalytics?: string;
  id?: string;
}

export const FilterRadioGroup = ({
  content,
  bestMatchTitle,
  groupLabel,
  options,
  defaultValue,
  model,
  submitBtnName,
  disableSubmitBtn = false,
  onOptionChange = () => {},
  onApply = () => {},
  labelFontSize = '16px',
  locationForAnalytics,
  id,
}: Props) => {
  const { t } = useTranslation();

  const mobileScreen = useMediaQuery(phoneOnly);
  const [radioValue, setRadioValue] = useState(
    defaultValue &&
      options.filter((option) => option.value === defaultValue).length > 0
      ? String(defaultValue)
      : ''
  );

  const suppressDisclaimer = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PATIENT_REVIEWS
  );

  const buttonStyles: any = {
    'abyss-button-root': {
      height: '42px',
      marginTop: '20px',
      border: 'none',
      borderRadius: '24px',
      width: mobileScreen ? '100%' : '',
      fontWeight: 600,
    },
  };

  useEffect(() => {
    setRadioValue(
      defaultValue &&
        options.filter((option) => option.value === defaultValue).length > 0
        ? String(defaultValue)
        : ''
    );
  }, [defaultValue]);

  // TODO: This data will be collated later for sending to API
  const onSubmit = () => {
    onApply(radioValue);
  };

  function onChangeRadioGroupOption(val) {
    if (val) {
      adobeLinkTrackEvent({
        name: options.filter((option) => val === option.value.toString())?.[0]
          ?.dnt_label,
        location: locationForAnalytics,
        type: 'radio',
      });
    }

    setRadioValue(val);
    onOptionChange({
      [model]: options.filter((option) => val === option.value.toString()),
    });
  }
  const infoContent = () =>
    content &&
    bestMatchTitle && (
      <Flex
        css={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '8px',
          fontSize: '16px',
          lineHeight: '20px',
          paddingTop: '16px',
        }}
      >
        <Distance
          ariaLabel={t(
            'ACCESSIBILITY_LABELS.INFORMATION_ABOUT_PREFERRED_PROVIDERS'
          )}
          content={content}
          tabIndex={0}
          title={bestMatchTitle}
          widths={297}
        />
        <Text
          css={{
            lineHeight: '18px',
            fontSize: '14px',
            color: '#196ECF',
          }}
        >
          {t('PREFFERED_PROVIDER_MEAN')}
        </Text>
      </Flex>
    );
  return (
    <React.Fragment>
      <Flex
        css={{
          'abyss-flex-root': {
            overflow: 'auto',
            maxHeight: '350px',
          },
        }}
        direction="column"
      >
        <RadioGroup
          css={{
            'abyss-radio-group-label': {
              margin: '0',
            },
            'abyss-radio-group-root': {
              border: 'none',
            },
          }}
          data-auto-testid="radio-group"
          data-testid={`${model}-group`}
          label={groupLabel}
          onChange={(e: { target: { value: string } }) => {
            onChangeRadioGroupOption(e.target.value);
          }}
          validators={{ required: false }}
          value={radioValue}
        >
          <div aria-label={id} role="radiogroup">
            {options.map(({ value, label }: Radio) => (
              <RadioGroup.Radio
                css={{
                  'abyss-radio-root': {
                    padding: '12px 0px 6px $xs',
                    margin: '0 !important',
                    alignItems: 'center !important',
                    '& > div': {
                      marginRight: '4px',
                    },
                  },
                  'abyss-radio-label': {
                    fontSize: labelFontSize,
                    lineHeight: '20px',
                    color:
                      value.toString() === radioValue ? '$gray8' : '$gray7',
                    '.abyss-text-root': {
                      fontSize: labelFontSize,
                      lineHeight: '20px',
                      color: value === radioValue ? '$gray8' : '$gray7',
                    },
                  },
                }}
                data-testid={`${
                  model === 'SortBy'
                    ? `sortBy-${value
                        .toString()
                        .replace(/ /g, '-')
                        .toLocaleLowerCase()}`
                    : model
                }-radio`}
                key={label}
                label={label}
                value={value.toString()}
              />
            ))}
          </div>
        </RadioGroup>
        {model === FilterCollectionModel.PATIENT_REVIEWS &&
          !suppressDisclaimer && <HealthGradesDisclaimer />}
      </Flex>
      {infoContent()}
      {model === FilterCollectionModel.DISTANCE ? <DistanceInfoIcon /> : null}
      {submitBtnName ? (
        <Button
          css={buttonStyles}
          data-auto-testid="apply-button"
          data-testid={`${model}-apply-button`}
          isDisabled={disableSubmitBtn}
          onClick={onSubmit}
        >
          {submitBtnName}
        </Button>
      ) : null}
    </React.Fragment>
  );
};
