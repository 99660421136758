import {
  FilterCollectionModel,
  FilterOptionsType,
  ReverseCoverageTypesCodes,
} from '../../common/Constants';
import {
  ACCEPTING_NEW_PATIENTS_CHIP_FILTERS,
  ACCEPTING_NEW_PATIENTS_DRAWER_FILTERS,
  ACCESSIBILITY_CODE_CHIP_FILTERS,
  ACCESSIBILITY_CODE_DRAWER_FILTERS,
  ACCOMMODATE_OFFERED_CHIP_FILTERS,
  ACCOMMODATE_OFFERED_DRAWER_FILTERS,
  ACO_CHIP_FILTERS,
  ACO_DRAWER_FILTERS,
  AGE_RANGE_CHIP_FILTERS,
  AGE_RANGE_DRAWER_FILTERS,
  AREA_OF_EXPERTISE_CHIP_FILTERS,
  AREA_OF_EXPERTISE_DRAWER_FILTERS,
  BEHAVIORAL_PROGRAM_INDICATOR_CHIP_FILTERS,
  BEHAVIORAL_PROGRAM_INDICATOR_DRAWER_FILTERS,
  BOARD_CERTIFIED_SPECIALITY_INDICATOR_CHIP_FILTERS,
  BOARD_CERTIFIED_SPECIALITY_INDICATOR_DRAWER_FILTERS,
  CULTURAL_COMPETENCE_CHIP_FILTERS,
  CULTURAL_COMPETENCE_DRAWER_FILTERS,
  DISTANCE_CHIP_FILTERS,
  DISTANCE_DRAWER_FILTERS,
  ESSENTIAL_COMMUNITY_PROVIDER_CHIP_FILTERS,
  ESSENTIAL_COMMUNITY_PROVIDER_DRAWER_FILTERS,
  ETHNICITY_CODE_CHIP_FILTERS,
  ETHNICITY_CODE_DRAWER_FILTERS,
  EVENING_AND_WEEKEND_CHIP_FILTERS,
  EVENING_AND_WEEKEND_DRAWER_FILTERS,
  FACILITY_TYPE_CHIP_FILTERS,
  FACILITY_TYPE_DRAWER_FILTERS,
  GENDER_CODE_CHIP_FILTERS,
  GENDER_CODE_DRAWER_FILTERS,
  HEALTH_SUPPLIES_OFFERED_CHIP_FILTERS,
  HEALTH_SUPPLIES_OFFERED_DRAWER_FILTERS,
  HOSPITAL_AFFILIATIONS_CHIP_FILTERS,
  HOSPITAL_AFFILIATIONS_DRAWER_FILTERS,
  LANGUAGE_CODE_CHIP_FILTERS,
  LANGUAGE_CODE_DRAWER_FILTERS,
  MEDICAL_CHIP_FILTERS,
  MEDICAL_DRAWER_FILTERS,
  PATIENT_REVIEWS_CHIP_FILTERS,
  PATIENT_REVIEWS_DRAWER_FILTERS,
  PREFERRED_FACILITY_CHIP_FILTERS,
  PREFERRED_FACILITY_DRAWER_FILTERS,
  PREMIUM_CARE_PHYSICIAN_CHIP_FILTERS,
  PREMIUM_CARE_PHYSICIAN_DRAWER_FILTERS,
  PROGRAM_CATEGORY_CHIP_FILTERS,
  PROGRAM_CATEGORY_DRAWER_FILTERS,
  PROGRAM_CHIP_FILTERS,
  PROGRAM_DRAWER_FILTERS,
  RACE_CODE_CHIP_FILTERS,
  RACE_CODE_DRAWER_FILTERS,
  SERVICES_OFFERED_CHIP_FILTERS,
  SERVICES_OFFERED_DRAWER_FILTERS,
  SPECIALTY_CODE_CHIP_FILTERS,
  SPECIALTY_CODE_DRAWER_FILTERS,
  TIERED_PROVIDERS_CHIP_FILTERS,
  TIERED_PROVIDERS_DRAWER_FILTERS,
  UPCOMING_AVAILABILITY_CHIP_FILTERS,
  UPCOMING_AVAILABILITY_DRAWER_FILTERS,
  VIRTUAL_CARE_CHIP_FILTERS,
  VIRTUAL_CARE_DRAWER_FILTERS,
  VIRTUAL_CARE_OPTIONS_CHIP_FILTERS,
  VIRTUAL_CARE_OPTIONS_DRAWER_FILTERS,
} from './chipAndDrawerFilters';
import {
  BEHAVIORAL_FILTER_CHIP_ORDER,
  BEHAVIORAL_FILTER_DRAWER_ORDER,
  DENTAL_FILTER_CHIP_ORDER,
  DENTAL_FILTER_DRAWER_ORDER,
  MEDICAL_FILTER_CHIP_ORDER,
  MEDICAL_FILTER_DRAWER_ORDER,
  VISION_FILTER_CHIP_ORDER,
  VISION_FILTER_DRAWER_ORDER,
} from './filterOrder';

export const getFiltersArray = (
  t,
  isMnr: boolean,
  isIfp: boolean,
  isCns: boolean,
  isACOFilterDisabled: boolean,
  isCulturalCompetencyFlag: boolean,
  toShowDistanceSlider: boolean,
  virtualCareFilterDisabled: boolean,
  isPreferredFacilityDisabled: boolean,
  ecpEnabled: boolean,
  upcomingAvailabilityFilterDisabled: boolean,
  isBehavioralFacilityFilterEnabled: boolean,
  isBoardCertifiedFilterEnabled: boolean,
  tieredProvidersFilterDisabled: boolean,
  premiumCarePhysicianFilterDisabled: boolean,
  accommodateOfferedFilterDisabled: boolean,
  showVirtualCareV2: boolean
) => {
  const BEHAVIORAL_HEALTH = ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'];
  const { MEDICAL, VISION, DENTAL } = ReverseCoverageTypesCodes;

  const getFilterOrder = (
    coverage: ReverseCoverageTypesCodes,
    model: FilterCollectionModel,
    isDrawer: boolean
  ): number | undefined => {
    const matcher = (filter) => filter === model;

    let index;

    switch (coverage) {
      case ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']:
        index = isDrawer
          ? BEHAVIORAL_FILTER_DRAWER_ORDER(isCns).findIndex(matcher)
          : BEHAVIORAL_FILTER_CHIP_ORDER.findIndex(matcher);
        break;
      case ReverseCoverageTypesCodes.DENTAL:
        index = isDrawer
          ? DENTAL_FILTER_DRAWER_ORDER.findIndex(matcher)
          : DENTAL_FILTER_CHIP_ORDER.findIndex(matcher);
        break;
      case ReverseCoverageTypesCodes.VISION:
        index = isDrawer
          ? VISION_FILTER_DRAWER_ORDER.findIndex(matcher)
          : VISION_FILTER_CHIP_ORDER.findIndex(matcher);
        break;
      case ReverseCoverageTypesCodes.MEDICAL:
      default:
        index = isDrawer
          ? MEDICAL_FILTER_DRAWER_ORDER(isCns).findIndex(matcher)
          : MEDICAL_FILTER_CHIP_ORDER(isMnr || isIfp).findIndex(matcher);
    }
    index++;

    return index > 0 ? index : undefined;
  };

  const getFilter = (
    label: string,
    title: string,
    model: FilterCollectionModel,
    type: FilterOptionsType,
    chipFilters,
    allFilterDrawerFilters,
    isDisabled: boolean = false,
    options: any[] = [],
    isSortingOptionsDisabled: boolean = false
  ) => ({
    label,
    title: t(title),
    model,
    type,
    chipFilters,
    chipFiltersOrder: {
      [MEDICAL]: getFilterOrder(MEDICAL, model, false),
      [BEHAVIORAL_HEALTH]: getFilterOrder(BEHAVIORAL_HEALTH, model, false),
      [VISION]: getFilterOrder(VISION, model, false),
      [DENTAL]: getFilterOrder(DENTAL, model, false),
    },
    allFilterDrawerFilters,
    allFilterDrawerOrder: {
      [MEDICAL]: getFilterOrder(MEDICAL, model, true),
      [BEHAVIORAL_HEALTH]: getFilterOrder(BEHAVIORAL_HEALTH, model, true),
      [VISION]: getFilterOrder(VISION, model, true),
      [DENTAL]: getFilterOrder(DENTAL, model, true),
    },
    options,
    isDisabled,
    isSortingOptionsDisabled,
  });

  return [
    getFilter(
      'ACO',
      'CONTENT_FILTERS.ACO',
      FilterCollectionModel.ACONAME,
      FilterOptionsType.CHECK_BOX,
      ACO_CHIP_FILTERS,
      ACO_DRAWER_FILTERS,
      isACOFilterDisabled
    ),
    getFilter(
      'Cultural Competency Training',
      'CONTENT_FILTERS.CULTURAL_COMPETENCE',
      FilterCollectionModel.CULTURAL_COMPETENCE,
      FilterOptionsType.CHECK_BOX,
      CULTURAL_COMPETENCE_CHIP_FILTERS,
      CULTURAL_COMPETENCE_DRAWER_FILTERS,
      !isCulturalCompetencyFlag
    ),
    getFilter(
      t('CONTENT_FILTERS.DISTANCE'),
      toShowDistanceSlider
        ? `${t('CONTENT_FILTERS.DISTANCE')} (miles)`
        : t('CONTENT_FILTERS.DISTANCE'),
      FilterCollectionModel.DISTANCE,
      toShowDistanceSlider ? FilterOptionsType.SLIDER : FilterOptionsType.RADIO,
      DISTANCE_CHIP_FILTERS,
      DISTANCE_DRAWER_FILTERS,
      false,
      [1, 5, 10, 25, 50, 100, 120].map((value, index) => ({
        label: `${value} ${t('CONTENT_FILTERS.MILE')}${value > 1 ? 's' : ''}`,
        value,
        c_value: index,
        dnt_label: `${value} miles`,
      }))
    ),
    getFilter(
      t('CONTENT_FILTERS.VIRTUAL_CARE'),
      t('CONTENT_FILTERS.VIRTUAL_CARE'),
      FilterCollectionModel.VIRTUAL_CARE,
      FilterOptionsType.CHECK_BOX,
      VIRTUAL_CARE_CHIP_FILTERS,
      VIRTUAL_CARE_DRAWER_FILTERS,
      virtualCareFilterDisabled,
      [
        {
          label: t('FILTER_OPTIONS.VirtualCare.Y'),
          dnt_label: t('FILTER_OPTIONS.VirtualCare.Y'),
          value: true,
        },
      ]
    ),
    getFilter(
      t('CONTENT_FILTERS.PATIENT_REVIEWS'),
      t('CONTENT_FILTERS.PATIENT_REVIEWS'),
      FilterCollectionModel.PATIENT_REVIEWS,
      FilterOptionsType.RADIO,
      PATIENT_REVIEWS_CHIP_FILTERS,
      PATIENT_REVIEWS_DRAWER_FILTERS,
      false,
      [
        {
          label: t('CONTENT_FILTERS.5_STARS'),
          value: '5',
          dnt_label: '5 Stars',
        },
        ...['4', '3', '2'].map((value) => ({
          label: `${value} ${t('CONTENT_FILTERS.STARS_AND_UP')}`,
          value,
          dnt_label: `${value} Stars and up`,
        })),
        {
          label: t('CONTENT_FILTERS.1_STAR'),
          value: '1',
          dnt_label: '1 Star and up',
        },
      ]
    ),
    getFilter(
      t('CONTENT_FILTERS.SPECIALTY'),
      t('CONTENT_FILTERS.SPECIALTY'),
      FilterCollectionModel.SPECIALTY_CODE,
      FilterOptionsType.CHECK_BOX,
      SPECIALTY_CODE_CHIP_FILTERS,
      SPECIALTY_CODE_DRAWER_FILTERS
    ),
    getFilter(
      t('CONTENT_FILTERS.AREAS_OF_EXPERTISE'),
      t('CONTENT_FILTERS.AREAS_OF_EXPERTISE'),
      FilterCollectionModel.AREA_OF_EXPERTISE,
      FilterOptionsType.CHECK_BOX,
      AREA_OF_EXPERTISE_CHIP_FILTERS,
      AREA_OF_EXPERTISE_DRAWER_FILTERS
    ),
    getFilter(
      t('CONTENT_FILTERS.FACILITY_TYPE'),
      t('CONTENT_FILTERS.FACILITY_TYPE'),
      FilterCollectionModel.FACILITY_TYPE,
      FilterOptionsType.CHECK_BOX,
      FACILITY_TYPE_CHIP_FILTERS,
      FACILITY_TYPE_DRAWER_FILTERS
    ),
    getFilter(
      t('CONTENT_FILTERS.PREFERRED_FACILITY'),
      t('CONTENT_FILTERS.PREFERRED_FACILITY'),
      FilterCollectionModel.PREFERRED_FACILITY,
      FilterOptionsType.CHECK_BOX,
      PREFERRED_FACILITY_CHIP_FILTERS,
      PREFERRED_FACILITY_DRAWER_FILTERS,
      !isPreferredFacilityDisabled
    ),
    getFilter(
      t('CONTENT_FILTERS.EVENING_WEEKENDS'),
      t('CONTENT_FILTERS.EVENING_WEEKENDS'),
      FilterCollectionModel.EVENING_AND_WEEKEND,
      FilterOptionsType.CHECK_BOX,
      EVENING_AND_WEEKEND_CHIP_FILTERS,
      EVENING_AND_WEEKEND_DRAWER_FILTERS
    ),
    getFilter(
      t('CONTENT_FILTERS.HEALTH_SUPPLIES_OFFERED'),
      t('CONTENT_FILTERS.HEALTH_SUPPLIES_OFFERED'),
      FilterCollectionModel.HEALTH_SUPPLIES_OFFERED,
      FilterOptionsType.CHECK_BOX,
      HEALTH_SUPPLIES_OFFERED_CHIP_FILTERS,
      HEALTH_SUPPLIES_OFFERED_DRAWER_FILTERS
    ),
    getFilter(
      t('CONTENT_FILTERS.ACCESSIBILITY'),
      t('CONTENT_FILTERS.ACCESSIBILITY'),
      FilterCollectionModel.ACCESSIBILITY_CODE,
      FilterOptionsType.CHECK_BOX,
      ACCESSIBILITY_CODE_CHIP_FILTERS,
      ACCESSIBILITY_CODE_DRAWER_FILTERS
    ),
    getFilter(
      t('CONTENT_FILTERS.LANGUAGE'),
      t('CONTENT_FILTERS.LANGUAGE'),
      FilterCollectionModel.LANGUAGE_CODE,
      FilterOptionsType.CHECK_BOX,
      LANGUAGE_CODE_CHIP_FILTERS,
      LANGUAGE_CODE_DRAWER_FILTERS
    ),
    getFilter(
      t('CONTENT_FILTERS.RACE'),
      t('CONTENT_FILTERS.RACE'),
      FilterCollectionModel.RACE_CODE,
      FilterOptionsType.CHECK_BOX,
      RACE_CODE_CHIP_FILTERS,
      RACE_CODE_DRAWER_FILTERS
    ),
    getFilter(
      t('CONTENT_FILTERS.GENDER'),
      t('CONTENT_FILTERS.GENDER'),
      FilterCollectionModel.GENDER_CODE,
      FilterOptionsType.RADIO,
      GENDER_CODE_CHIP_FILTERS,
      GENDER_CODE_DRAWER_FILTERS
    ),
    getFilter(
      t('CONTENT_FILTERS.ETHNICITY'),
      t('CONTENT_FILTERS.ETHNICITY'),
      FilterCollectionModel.ETHNICITY_CODE,
      FilterOptionsType.CHECK_BOX,
      ETHNICITY_CODE_CHIP_FILTERS,
      ETHNICITY_CODE_DRAWER_FILTERS
    ),
    getFilter(
      t('CONTENT_FILTERS.PROVIDER_GROUP'),
      t('CONTENT_FILTERS.PROVIDER_GROUP'),
      FilterCollectionModel.MEDICAL,
      FilterOptionsType.CHECK_BOX,
      MEDICAL_CHIP_FILTERS,
      MEDICAL_DRAWER_FILTERS
    ),
    getFilter(
      t('CONTENT_FILTERS.HOSPITAL_AFFILIATIONS'),
      t('CONTENT_FILTERS.HOSPITAL_AFFILIATIONS'),
      FilterCollectionModel.HOSPITAL_AFFILIATIONS,
      FilterOptionsType.CHECK_BOX,
      HOSPITAL_AFFILIATIONS_CHIP_FILTERS,
      HOSPITAL_AFFILIATIONS_DRAWER_FILTERS
    ),
    getFilter(
      t('CONTENT_FILTERS.ESSENTIAL_COMMUNITY_PROVIDER'),
      t('CONTENT_FILTERS.ESSENTIAL_COMMUNITY_PROVIDER'),
      FilterCollectionModel.ESSENTIAL_COMMUNITY_PROVIDER,
      FilterOptionsType.RADIO,
      ESSENTIAL_COMMUNITY_PROVIDER_CHIP_FILTERS,
      ESSENTIAL_COMMUNITY_PROVIDER_DRAWER_FILTERS,
      !ecpEnabled,
      [],
      true
    ),
    getFilter(
      t('CONTENT_FILTERS.ACCEPTING_NEW_PATIENTS'),
      t('CONTENT_FILTERS.ACCEPTING_NEW_PATIENTS'),
      FilterCollectionModel.ACCEPTING_NEW_PATIENTS,
      FilterOptionsType.RADIO,
      ACCEPTING_NEW_PATIENTS_CHIP_FILTERS(isMnr || isIfp),
      ACCEPTING_NEW_PATIENTS_DRAWER_FILTERS
    ),
    getFilter(
      t('CONTENT_FILTERS.PROGRAMS'),
      t('CONTENT_FILTERS.PROGRAMS'),
      FilterCollectionModel.BEHAVIORAL_PROGRAM_INDICATOR,
      FilterOptionsType.CHECK_BOX,
      BEHAVIORAL_PROGRAM_INDICATOR_CHIP_FILTERS,
      BEHAVIORAL_PROGRAM_INDICATOR_DRAWER_FILTERS
    ),
    getFilter(
      t('CONTENT_FILTERS.SERVICES_PROVIDED'),
      t('CONTENT_FILTERS.SERVICES_PROVIDED'),
      FilterCollectionModel.SERVICES_OFFERED,
      FilterOptionsType.CHECK_BOX,
      SERVICES_OFFERED_CHIP_FILTERS,
      SERVICES_OFFERED_DRAWER_FILTERS
    ),
    getFilter(
      t('CONTENT_FILTERS.UPCOMING_AVAILABILITY'),
      t('CONTENT_FILTERS.UPCOMING_AVAILABILITY'),
      FilterCollectionModel.UPCOMING_AVAILABILITY,
      FilterOptionsType.CHECK_BOX,
      UPCOMING_AVAILABILITY_CHIP_FILTERS,
      UPCOMING_AVAILABILITY_DRAWER_FILTERS,
      upcomingAvailabilityFilterDisabled,
      [2, 7, 14, 30].map((value) => ({
        label: `Next ${value} days`,
        value,
        dnt_label: `Next ${value} days`,
      })),
      true
    ),
    getFilter(
      t('CONTENT_FILTERS.AGE_RANGE'),
      t('CONTENT_FILTERS.AGE_RANGE'),
      FilterCollectionModel.AGE_RANGE,
      FilterOptionsType.CHECK_BOX,
      AGE_RANGE_CHIP_FILTERS,
      AGE_RANGE_DRAWER_FILTERS,
      !isBehavioralFacilityFilterEnabled,
      [],
      true
    ),
    getFilter(
      t('CONTENT_FILTERS.PROGRAM'),
      t('CONTENT_FILTERS.PROGRAM'),
      FilterCollectionModel.PROGRAM,
      FilterOptionsType.CHECK_BOX,
      PROGRAM_CHIP_FILTERS,
      PROGRAM_DRAWER_FILTERS,
      !isBehavioralFacilityFilterEnabled,
      [],
      true
    ),
    getFilter(
      t('CONTENT_FILTERS.PROGRAM_CATEGORY'),
      t('CONTENT_FILTERS.PROGRAM_CATEGORY'),
      FilterCollectionModel.PROGRAM_CATEGORY,
      FilterOptionsType.CHECK_BOX,
      PROGRAM_CATEGORY_CHIP_FILTERS,
      PROGRAM_CATEGORY_DRAWER_FILTERS,
      !isBehavioralFacilityFilterEnabled,
      [],
      true
    ),
    getFilter(
      t('CONTENT_FILTERS.BOARD_CERTIFIED'),
      t('CONTENT_FILTERS.BOARD_CERTIFIED'),
      FilterCollectionModel.BOARD_CERTIFIED_SPECIALITY_INDICATOR,
      FilterOptionsType.CHECK_BOX,
      BOARD_CERTIFIED_SPECIALITY_INDICATOR_CHIP_FILTERS,
      BOARD_CERTIFIED_SPECIALITY_INDICATOR_DRAWER_FILTERS,
      !isBoardCertifiedFilterEnabled,
      [
        {
          label: t('FILTER_OPTIONS.BoardCertifiedSpecialtyIndicator.Y'),
          dnt_label: t('FILTER_OPTIONS.BoardCertifiedSpecialtyIndicator.Y'),
          value: true,
        },
      ]
    ),
    getFilter(
      t('CONTENT_FILTERS.TIERED_PROVIDERS'),
      t('CONTENT_FILTERS.TIERED_PROVIDERS'),
      FilterCollectionModel.TIERED_PROVIDERS,
      FilterOptionsType.CHECK_BOX,
      TIERED_PROVIDERS_CHIP_FILTERS,
      TIERED_PROVIDERS_DRAWER_FILTERS,
      tieredProvidersFilterDisabled,
      [
        {
          label: t('CONTENT_FILTERS.TIER_1'),
          value: FilterCollectionModel.TIERED_PROVIDER,
          dnt_label: 'Tier 1',
        },
        {
          label: t('CONTENT_FILTERS.TIER_2'),
          value: FilterCollectionModel.TIERED2_PROVIDER,
          dnt_label: 'Tier 2',
        },
      ]
    ),
    getFilter(
      t('CONTENT_FILTERS.PREMIUM_CARE_PHYSICIAN'),
      t('CONTENT_FILTERS.PREMIUM_CARE_PHYSICIAN'),
      FilterCollectionModel.PREMIUM_CARE_PHYSICIAN,
      FilterOptionsType.CHECK_BOX,
      PREMIUM_CARE_PHYSICIAN_CHIP_FILTERS,
      PREMIUM_CARE_PHYSICIAN_DRAWER_FILTERS,
      premiumCarePhysicianFilterDisabled,
      [
        {
          label: t('FILTER_OPTIONS.PremiumCarePhysician.Y'),
          dnt_label: t(
            'CONTENT_FILTERS.ALL_FILTERS_DRAWER.PREMIUM_CARE_PHYSICIAN'
          ),
          value: true,
        },
      ]
    ),
    getFilter(
      t('CONTENT_FILTERS.ACCOMMODATE_OFFERED'),
      t('CONTENT_FILTERS.ACCOMMODATE_OFFERED'),
      FilterCollectionModel.ACCOMMODATE_OFFERED,
      FilterOptionsType.CHECK_BOX,
      ACCOMMODATE_OFFERED_CHIP_FILTERS,
      ACCOMMODATE_OFFERED_DRAWER_FILTERS,
      accommodateOfferedFilterDisabled
    ),
    getFilter(
      t('CONTENT_FILTERS.VIRTUAL_CARE_OPTIONS'),
      t('CONTENT_FILTERS.VIRTUAL_CARE_OPTIONS'),
      FilterCollectionModel.VIRTUAL_CARE_OPTIONS,
      FilterOptionsType.RADIO,
      VIRTUAL_CARE_OPTIONS_CHIP_FILTERS,
      VIRTUAL_CARE_OPTIONS_DRAWER_FILTERS,
      !showVirtualCareV2
    ),
  ];
};
