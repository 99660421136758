import { Layout } from '@abyss/web/ui/Layout';
import React from 'react';

import { ChoosePCPAlert } from '../../../../common/Alert/ChoosePCPAlert';
import { CardWrapper } from '../../../../common/CardWrapper';
import { ChipsCategory } from '../../../../common/ChipsCategory';
import {
  CareCategories,
  Constants,
  ProviderTypes,
  ReverseCoverageTypesCodes,
} from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { DisclaimerContent } from '../../../../common/DisclaimerContent';
import { EAPCode } from '../../../../common/EAPCode';
import { EAPMixResultsContainer } from '../../../../common/EAPCode/EAPCode.styled';
import {
  getFilteredChips,
  validateChipsToTabEnabled,
} from '../../../../common/PSXHeader/SearchBar/utils';
import { ResultSection } from '../../../../common/ResultSection/ResultSection';
import { getFeatureFlag } from '../../../../common/Utils';
import { useCoverageType } from '../../../../hooks/useCoverageType';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { useLagoon } from '../../../../hooks/useLagoon';
import { Option } from '../../../../models/ProviderSearch';
import { getCurrentMember } from '../../../../utils/user.utils';
import { HealthGradesContainerStyled } from '../PSXHome/HealthGradesContainer.styled';
import { SearchResultsDynamicTitle } from './SearchResultsDynamicTitle';
import { SearchResultsTitle } from './SearchResultsTitle';

type Props = {
  acceptingNewPatients?: boolean;
  choosePCP: boolean;
  contextValue: string;
  data;
  dependentSeqNbr: string;
  eapCodeFlag: boolean;
  facilitiesHeaders;
  isMixedSuggestions: boolean;
  isPageTracked: boolean;
  isNameSearchEnabled?: boolean;
  isSpecialtySearchEnabled?: boolean;
  medGrpIsLoading: boolean;
  medicalGroupHeaders;
  onSpecialtyResultClicked(isSpecialtySearch: boolean, title: string): void;
  orgIsLoading: boolean;
  preferredFacility: string[];
  providerHeaders;
  providerIsLoading: boolean;
  resultType: string;
  searchMethod: string;
  searchTerm: string;
  searchTitle: string;
  searchType: string;
  showFacilities: boolean;
  showProviders: boolean;
  showProviderGroups: boolean;
  specialtyCode: string;
  specialtySearch: boolean;
  userZip: string;
  virtualCare: boolean;
  hasOnlyPseudoRollupCodes: boolean;
  invokeLlmModel?: boolean;
  areaOfExpertise?: Option[];
  specialtyCodeFromQuery: Option[];
  genderCode: Option[];
  virtualCareCode?: Option;
  preSelectEapFilter?: boolean;
  isMixedResultsPage?: boolean;
  categoryCount?: { chipEnabled: boolean; chipCategory: string }[];
  showChips?: boolean;
  specialtyResults?: { chipEnabled: boolean; chipCategory: string }[];
  essentialCommunityProviderCode?: Option[];
};

export const SearchedResultSectionData = ({
  acceptingNewPatients,
  dependentSeqNbr,
  preferredFacility,
  providerHeaders,
  medicalGroupHeaders,
  facilitiesHeaders,
  searchTitle,
  searchMethod,
  searchTerm,
  searchType,
  contextValue,
  specialtyCode,
  userZip,
  choosePCP,
  isMixedSuggestions,
  isNameSearchEnabled,
  isSpecialtySearchEnabled,
  onSpecialtyResultClicked,
  resultType,
  specialtySearch,
  data,
  eapCodeFlag,
  providerIsLoading,
  medGrpIsLoading,
  orgIsLoading,
  isPageTracked,
  showFacilities,
  showProviders,
  showProviderGroups,
  virtualCare,
  hasOnlyPseudoRollupCodes,
  invokeLlmModel,
  areaOfExpertise,
  specialtyCodeFromQuery,
  genderCode,
  virtualCareCode,
  preSelectEapFilter = false,
  isMixedResultsPage = false,
  categoryCount = [],
  showChips,
  specialtyResults = [],
  essentialCommunityProviderCode,
}: Props) => {
  const coverageType = useCoverageType();
  const [enableMapViewEnhancements] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_LIST_VIEW_MAP_ENHANCEMENTS,
  ]);
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();
  const chipsToTabsEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.CARE_CATEGORY_CHIPS_TO_TABS_ENABLED
  );
  const visionChipEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.VISION_CHIP_ENABLED
  );
  const dentalChipEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DENTAL_CHIP_ENABLED
  );
  const enableMixedResultPageUpdatedDesign: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_MIXED_RESULT_PAGE_UPDATED_DESIGN
  );
  const currentMember = getCurrentMember();
  const filteredChips = getFilteredChips(
    currentMember,
    isMixedResultsPage,
    categoryCount,
    visionChipEnabled,
    dentalChipEnabled
  );
  const chipCount = filteredChips.filter((chip) => chip.enabled).length;

  const chipsEnabled = validateChipsToTabEnabled(
    chipsToTabsEnabled,
    isMixedResultsPage
  );

  const isBHCare: boolean = coverageType?.includes(
    ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']
  );

  const ResultsSectionMain = (
    res,
    secType,
    count,
    sectionProviderType,
    radius,
    isLoading,
    headers
  ) => (
    <ResultSection
      acceptingNewPatients={acceptingNewPatients}
      areaOfExpertise={areaOfExpertise}
      dependentSeqNbr={dependentSeqNbr}
      essentialCommunityProviderCode={essentialCommunityProviderCode}
      genderCode={genderCode}
      headers={headers}
      invokeLlmModel={invokeLlmModel}
      isLoading={isLoading}
      preSelectEapFilter={preSelectEapFilter}
      preferredFacility={preferredFacility}
      providerType={sectionProviderType}
      results={res}
      search={searchTitle}
      searchMethod={searchMethod}
      searchRadius={radius}
      searchTerm={searchTerm}
      searchType={searchType}
      sectionType={secType}
      selectedChip={contextValue}
      specialtyCode={specialtyCode}
      specialtyCodeFromQuery={specialtyCodeFromQuery}
      totalCount={count}
      userZip={userZip}
      virtualCare={virtualCare}
      virtualCareCode={virtualCareCode}
    />
  );

  return (
    <React.Fragment>
      <CardWrapper>
        <Layout.Stack grow space={0}>
          {!enableMapViewEnhancements && <ChoosePCPAlert />}
          {enableMixedResultPageUpdatedDesign ? (
            <SearchResultsDynamicTitle
              chipCount={chipCount}
              choosePCP={choosePCP}
              hasOnlyPseudoRollupCodes={hasOnlyPseudoRollupCodes}
              isMixedSuggestions={isMixedSuggestions}
              isNameSearchEnabled={isNameSearchEnabled}
              isSpecialtySearchEnabled={isSpecialtySearchEnabled}
              offset={1}
              onSpecialtyClick={onSpecialtyResultClicked}
              searchTitle={searchTitle}
              specialtyResults={specialtyResults}
              specialtySearch={specialtySearch}
            />
          ) : (
            <SearchResultsTitle
              choosePCP={choosePCP}
              hasOnlyPseudoRollupCodes={hasOnlyPseudoRollupCodes}
              isMixedSuggestions={isMixedSuggestions}
              isNameSearchEnabled={isNameSearchEnabled}
              isSpecialtySearchEnabled={isSpecialtySearchEnabled}
              offset={1}
              onSpecialtyClick={onSpecialtyResultClicked}
              resultType={resultType}
              searchTitle={searchTitle}
              specialtyResults={specialtyResults}
              specialtySearch={specialtySearch}
            />
          )}
          {showChips && chipsEnabled && enableMixedResultPageUpdatedDesign && (
            <Layout.Stack grow space={0}>
              <ChipsCategory
                chips={filteredChips}
                data-auto-testid="search-home-chips-category"
                data-testid="search-home-chips-category"
                isMixedResultsPage={isMixedResultsPage}
                resultSection={true}
              />
            </Layout.Stack>
          )}
          {!enableMapViewEnhancements &&
            contextValue === CareCategories.BEHAVIORAL_HEALTH &&
            eapCodeFlag &&
            isBHCare &&
            isPageTracked && (
              <EAPMixResultsContainer>
                <EAPCode />
              </EAPMixResultsContainer>
            )}
          {contextValue !== CareCategories.FACILITIES &&
          contextValue !== CareCategories.HEALTH_SUPPLIES &&
          showProviders
            ? ResultsSectionMain(
                data?.providers,
                Constants.SEARCH_TYPES.PROVIDER,
                data?.totalCountPractitioners,
                ProviderTypes.PRACTITIONER,
                data?.practitionerSearchRadius,
                providerIsLoading,
                providerHeaders
              )
            : null}
          {contextValue !== CareCategories.FACILITIES &&
          contextValue !== CareCategories.HEALTH_SUPPLIES &&
          showProviderGroups
            ? ResultsSectionMain(
                data?.medicalGroup,
                Constants.SEARCH_TYPES.PROVIDER_GROUP,
                data?.totalCountMedicalGroup,
                ProviderTypes.MEDICAL_GROUP,
                data?.medicalGroupSearchRadius,
                medGrpIsLoading,
                medicalGroupHeaders
              )
            : null}
          {!choosePCP && showFacilities
            ? ResultsSectionMain(
                data?.facilities,
                Constants.SEARCH_TYPES.FACILITY,
                data?.totalCountOrganizations,
                ProviderTypes.ORGANIZATION,
                data?.organizationSearchRadius,
                orgIsLoading,
                facilitiesHeaders
              )
            : null}
        </Layout.Stack>
      </CardWrapper>
      <HealthGradesContainerStyled>
        <DisclaimerContent selectedChipValue={contextValue} />
      </HealthGradesContainerStyled>
    </React.Fragment>
  );
};
