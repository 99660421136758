import { Layout } from '@abyss/web/ui/Layout';
import { Skeleton } from '@abyss/web/ui/Skeleton';

import {
  SkeletonContainerDesktop,
  SkeletonContainerMobile,
} from './DataCard.style';

type Props = {
  fromMobileListView?: boolean;
  mobileScreen: boolean;
};

const DataCardSkeletonDesktop = () => (
  <SkeletonContainerDesktop>
    <Layout.Stack data-testid="skeleton-loader-data-card">
      <Layout.Group
        alignItems="top"
        css={{
          position: 'relative',
          left: '-30px',
          top: '8px',
        }}
      >
        <Skeleton height={48} variant="circular" width={48} />
        <Layout.Stack
          css={{
            position: 'relative',
            left: '11px',
            top: '4px',
          }}
          data-testid="skeleton-loader-cards"
          space={16}
        >
          <Skeleton height={16} variant="rounded" width={238} />
          <Skeleton height={16} variant="rounded" width={238} />
          <Skeleton height={16} variant="rounded" width={238} />
        </Layout.Stack>
      </Layout.Group>

      <Layout.Stack
        css={{
          position: 'relative',
          top: '34px',
          left: '11px',
        }}
        space={16}
      >
        <Skeleton height={16} variant="rounded" width={360} />
        <Skeleton height={16} variant="rounded" width={360} />
      </Layout.Stack>
    </Layout.Stack>
  </SkeletonContainerDesktop>
);

const DataCardSkeletonMobile = () => (
  <SkeletonContainerMobile>
    <Layout.Stack data-testid="skeleton-loader-data-card-mobile">
      <Layout.Group
        alignItems="top"
        css={{
          position: 'relative',
          left: '-9px',
          top: '8px',
        }}
      >
        <Skeleton height={48} variant="circular" width={48} />
        <Layout.Stack
          css={{
            position: 'relative',
            top: '4px',
            left: '10px',
          }}
          space={16}
        >
          <Skeleton height={16} variant="rounded" width={195} />
          <Skeleton height={16} variant="rounded" width={195} />
          <Skeleton height={16} variant="rounded" width={195} />
        </Layout.Stack>
      </Layout.Group>

      <Layout.Stack
        css={{
          position: 'relative',
          top: '22px',
          left: '16px',
        }}
        space={16}
      >
        <Skeleton height={16} variant="rounded" width={284} />
        <Skeleton height={16} variant="rounded" width={284} />
      </Layout.Stack>
    </Layout.Stack>
  </SkeletonContainerMobile>
);

export const DataCardSkeleton = ({
  fromMobileListView,
  mobileScreen,
}: Props) => {
  if (mobileScreen && !fromMobileListView) {
    return <DataCardSkeletonMobile />;
  }
  return <DataCardSkeletonDesktop />;
};
