import { useForm } from '@abyss/web/hooks/useForm';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { Alert } from '@abyss/web/ui/Alert';
import { Flex } from '@abyss/web/ui/Flex';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import find from 'lodash/find';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useCurrentMemberIsPreEffective } from '../../hooks/useCurrentMemberIsPreEffective';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { useGetRecommendationPreferencesStatus } from '../../hooks/useGetRecommendationPreferencesStatus';
import { useLagoon } from '../../hooks/useLagoon';
import { useSmartChoice } from '../../hooks/useSmartChoice';
import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';
import { useAnalyticsStore } from '../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../store/useAnalyticsStore/analyticsStore';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../store/useDetailsStore';
import { DetailsStore } from '../../store/useDetailsStore/detailsStore';
import { usePCPStore } from '../../store/usePCPStore';
import { PCPSearchStore } from '../../store/usePCPStore/pcpStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import { getMemberHealthCoverageType } from '../../utils/providerSearch.utils';
import { getMemberLocation } from '../../utils/psxHeader.utils';
import { getCurrentMember } from '../../utils/user.utils';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { PSXHeaderCardWrapper } from '../CardWrapper';
import { ChipsCategory } from '../ChipsCategory';
import {
  CareCategories,
  Constants,
  CoverageTypesCodes,
  LOB,
  RESULTS_PAGE_BREADCRUMB_TITLES,
  SEARCH_RESULTS_PAGE,
} from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { mobileOnly, phoneOnly } from '../ConstantsStyles';
import { AlertStyling } from '../DetailsPageHeader/DetailsPageHeader.styled';
import { LinkContainer } from '../LinkContainer/LinkContainer';
import { TabsCategory } from '../TabsCategory';
import { getFeatureFlag } from '../Utils';
import { getTypeAheadCategory } from '../Utils/adobeTrackUtils/adobeTrackUtils';
import {
  dateFormatToDate,
  getFormattedDate,
} from '../Utils/datesUtils/formatDate';
import { findLoggedInMember } from '../Utils/memberUtils/memberUtils';
import { Breadcrumb } from './Breadcrumb';
import { DemoHeader } from './DemoHeader/index';
import { MemberSelectionHeader } from './MemberSelectionHeader';
import { MemberInfo } from './ObapiDemo/memberProfile';
import { smartChoiceFlex, smartChoiceLinkStyle } from './PSXHeader.styled';
import { SearchBar } from './SearchBar';
import {
  getFilteredChips,
  handleSearchSelected,
  validateChipsToTabEnabled,
} from './SearchBar/utils';
import { OnboardingForm, PreferencesForm } from './SmartChoice';

const ChoosePCPHeader = lazy(async () => ({
  default: (await import('./ChoosePCPHeader/ChoosePCPHeader')).ChoosePCPHeader,
}));

type Props = {
  breadcrumbs?: any[];
  customMessage?: string;
  dataTestId?: string;
  isMixedResultsPage?: boolean;
  categoryCount?: { chipEnabled: boolean; chipCategory: string }[];
  onMemberIdChange?: (a: number) => void;
  onMemberDataChange?: (a: MemberInfo[]) => void;
  showChips?: boolean;
  showMemberSelection?: boolean;
  showChoosePCPHeader?: boolean;
  showSearchInputBackButton?: boolean;
};

export const PSXHeader = ({
  breadcrumbs = [],
  customMessage,
  dataTestId = '',
  isMixedResultsPage = false,
  categoryCount = [],
  onMemberIdChange = () => {},
  onMemberDataChange = () => {},
  showChips = true,
  showMemberSelection = true,
  showChoosePCPHeader = false,
  showSearchInputBackButton = false,
}: Props) => {
  const { t } = useTranslation();
  const url = window.location.href;
  const resultsPage: boolean = url?.includes('results/mapview') ? true : false;

  const [enableMapViewEnhancements] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_LIST_VIEW_MAP_ENHANCEMENTS,
  ]);
  const mobileScreen = useMediaQuery(
    enableMapViewEnhancements && resultsPage ? mobileOnly : phoneOnly
  );
  const isMobileScreen = useMediaQuery(mobileOnly);

  const { MEMBER_LOCATION, ACTIVE_MEMBER_INDEX, LOGGED_IN_MEMBER_INDEX } =
    Constants.STORAGE_KEYS.SESSION;
  const { ZIPCODE } = Constants.STORAGE_KEYS.LOCAL;

  const { navigate } = useRouter();

  const [searchType, setSearchType] = useState('');
  const [providerType, setProviderType] = useState('');
  const [showOnboardingForm, setShowOnboardingForm] = useState(false);
  const [isPreferencesModalOpen, setPreferencesModalOpen] =
    React.useState(false);
  const [lob, setLob] = useState<LOB>(LOB.ENI);
  const obapimembers = useStore(StoreKeys.OBAPI_MEMBERS);
  const big5 = useStore(StoreKeys.PLAN_SELECTOR);
  const [memberProfiles, setMemberProfiles] =
    useState<MemberInfo[]>(obapimembers);
  const [memberLocation, setMemberLocation] = useState<string>(
    storage.session.get(MEMBER_LOCATION) ?? ''
  );
  const [activeMemberIndex, setActiveMemberIndex] = useState(
    Number(storage.session.get(ACTIVE_MEMBER_INDEX)) ?? 0
  );
  const [activeUser, setActiveUser] = useState<MemberInfo>(
    memberProfiles[activeMemberIndex]
  );
  const { setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );
  const lagoonData = useLagoon('ui-messaging')();

  const memberNotification = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.PRE_EFFECTIVE_NOTIFICATION,
  });

  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { setCoverageType, setChipValue, chipValue } = chipStore;

  const { setAnalyticsState } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      setAnalyticsState: state.setAnalyticsState,
    }))
  );
  const { setPCPSearchState } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      setPCPSearchState: state.setPCPSearchState,
    }))
  );
  const { setDetailsStore } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      setDetailsStore: state.setDetailsStore,
    }))
  );

  const [preferencesData, getPreferenceData] =
    useGetRecommendationPreferencesStatus({});

  const isSmartChoice = useSmartChoice();
  React.useEffect(() => {
    if (typeof getPreferenceData === 'function' && !isPreferencesModalOpen) {
      getPreferenceData();
    }
  }, [isPreferencesModalOpen]);

  const currentZipcode = storage.local.get(ZIPCODE);

  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();
  const chipsToTabsEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.CARE_CATEGORY_CHIPS_TO_TABS_ENABLED
  );
  const visionChipEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.VISION_CHIP_ENABLED
  );
  const dentalChipEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DENTAL_CHIP_ENABLED
  );
  const disableMemberSelector: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISABLE_MEMBER_SELECTOR
  );
  const ispsxProviderRecommendationEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_PSX_PROVIDER_RECOMMENDATIONS
  );

  const isSingleBreadcrumb = breadcrumbs.length === 1;
  const isSearchResultsPage = breadcrumbs[0]?.title === SEARCH_RESULTS_PAGE;
  const isResultsPage =
    isSingleBreadcrumb &&
    !isSearchResultsPage &&
    RESULTS_PAGE_BREADCRUMB_TITLES.includes(breadcrumbs[0]?.title);
  const areBothBreadcrumbsValid =
    breadcrumbs.length > 1 &&
    RESULTS_PAGE_BREADCRUMB_TITLES.includes(breadcrumbs[0]?.title) &&
    RESULTS_PAGE_BREADCRUMB_TITLES.includes(breadcrumbs[1]?.title);

  const displayMemberSelectorOptions: boolean = !(
    disableMemberSelector &&
    (isResultsPage || areBothBreadcrumbsValid)
  );
  const currentMember = getCurrentMember();
  const lineOfBusiness = currentMember?.lineOfBusiness;
  const medicalCoverage = getMemberHealthCoverageType(
    currentMember?.eligibility,
    CoverageTypesCodes.MEDICAL
  );
  const isPreEffective = useCurrentMemberIsPreEffective();

  const filteredChips = getFilteredChips(
    currentMember,
    isMixedResultsPage,
    categoryCount,
    visionChipEnabled,
    dentalChipEnabled
  );

  const chipsEnabled = validateChipsToTabEnabled(
    chipsToTabsEnabled,
    isMixedResultsPage
  );

  const form = useForm({
    defaultValues: {
      'care-category': 'all',
      'member-select': activeMemberIndex.toString(),
      search: '',
      includeSpecialityRollupCodes: '',
      searchType: '',
      firstName: 'FNUUYDQFZCCW',
      lastName: 'LNLNUBNRSM',
      dob: '02-02-1979',
      memberId: '00990062383',
      groupNumber: '0168504',
      delsysCode: '52',
      portalSource: '',
      userName: '',
      id: '',
      lob: LOB.ENI,
    },
  });

  useEffect(() => {
    if (!currentZipcode) {
      form?.setValue('userZip', '07104');
      storage.local.set(ZIPCODE, '07104');
    }
  }, []);

  useEffect(() => {
    const loggedInMemberIndex = findLoggedInMember(memberProfiles, big5.bigN);
    if (!storage.session.get(ACTIVE_MEMBER_INDEX)) {
      storage.session.set(ACTIVE_MEMBER_INDEX, loggedInMemberIndex?.toString());
    }

    if (!storage.session.get(LOGGED_IN_MEMBER_INDEX)) {
      storage.session.set(LOGGED_IN_MEMBER_INDEX, loggedInMemberIndex);
    }
    if (memberProfiles) {
      const memberIndex = Number(storage.session.get(ACTIVE_MEMBER_INDEX));
      form.setValue('member-select', memberIndex.toString());
      const location = getMemberLocation(memberProfiles[activeMemberIndex]);
      storage.session.set(MEMBER_LOCATION, location);
      setMemberLocation(location);
      setActiveMemberIndex(memberIndex);
      setActiveUser(memberProfiles[memberIndex]);
    }
  }, [memberProfiles, activeMemberIndex, lineOfBusiness]);

  const onSubmit = (data) => {
    const searchTerm = form.getValues('searchTerm');
    const analyticsLinkName = 'search button';
    const typeaheadLinkName = 'typeahead search';
    const specialityPreferenceTable = useLagoon(
      Constants.LAGOON_TABLE.SPECIALITY_PREFERENCE
    )().map((item) => ({
      rollupCode: item.rollupCode,
      specialityCode: item.specialityCode,
    }));
    if (searchTerm?.length > 1) {
      // TODO: This handleSearchSelected is not being used, should be rechecked and removed.
      handleSearchSelected(
        false,
        specialityPreferenceTable,
        {
          ...data,
          searchTerm,
          linkName: analyticsLinkName,
          typeaheadLinkName,
          searchMethod: 'typed',
        },
        {
          chipStore,
          setTypeaheadSearchStore,
          setAnalyticsState,
          setPCPSearchState,
          setChipValue,
          setCoverageType,
          setDetailsStore,
        },
        navigate
      );
    }

    let analyticsLocationName = 'search-input-option-list';
    let analyticsSearchBlock;
    if (data?.searchType) {
      analyticsLocationName += `:${getTypeAheadCategory(data?.resultType)}`;
      analyticsSearchBlock = {
        linkPosition: '1',
      };
    }
    adobeLinkTrackEvent({
      name: analyticsLinkName,
      location: analyticsLocationName,
      type: 'internal',
      destinationUrl: '',
      ...(analyticsSearchBlock && { searchBlock: analyticsSearchBlock }),
    });
  };

  const displayMobileBreadcrumbs = () => {
    if (mobileScreen && !showSearchInputBackButton) {
      return <Breadcrumb breadcrumbs={breadcrumbs} />;
    }
    return null;
  };
  const coverageStartDate = medicalCoverage?.coverageEffDateRange?.startDate;

  const isMedicalOrPrimaryCare = [
    CareCategories.ALL,
    CareCategories.MEDICAL_SPECIALISTS,
    CareCategories.PRIMARY_CARE,
  ].includes(chipValue as CareCategories);

  const onClickSmartChoiceHandler = () => {
    if (
      preferencesData?.data?.recommendationPreferencesStatus
        ?.hasSetPreferences === 'y'
    ) {
      setPreferencesModalOpen(true);
    } else {
      setShowOnboardingForm(true);
    }
  };
  return (
    <React.Fragment>
      {isPreEffective && (
        <Alert
          children={memberNotification?.message}
          css={AlertStyling}
          data-testid="member-notification-alert"
          title={`${memberNotification?.title} ${
            lineOfBusiness === LOB.IFP
              ? dateFormatToDate(coverageStartDate)
              : getFormattedDate(coverageStartDate)
          }`}
          variant="info"
        />
      )}
      <PSXHeaderCardWrapper
        backgroundColor={'$bgHeaderColor'}
        showHeaderStyleforMap={
          enableMapViewEnhancements && resultsPage ? true : false
        }
        showSearchInputBackButton
      >
        {!mobileScreen && !showChoosePCPHeader ? (
          <Breadcrumb breadcrumbs={breadcrumbs} />
        ) : null}
        <FormProvider
          data-auto-testid={dataTestId}
          data-testid={dataTestId}
          onSubmit={onSubmit}
          state={form}
        >
          <Layout.Stack grow space={0}>
            <Layout.Group alignItems="top" css={{ position: 'relative' }}>
              {displayMobileBreadcrumbs()}
              {showMemberSelection && displayMemberSelectorOptions ? (
                <MemberSelectionHeader
                  activeUser={activeUser}
                  form={form}
                  memberProfiles={memberProfiles}
                  setActiveMemberIndex={setActiveMemberIndex}
                />
              ) : null}
              {showChoosePCPHeader ? (
                <Suspense fallback={<div>Loading...</div>}>
                  <Layout.Stack grow space={0}>
                    {!mobileScreen ? (
                      <Breadcrumb breadcrumbs={breadcrumbs} />
                    ) : null}
                    <ChoosePCPHeader />
                  </Layout.Stack>
                </Suspense>
              ) : null}
              {isSmartChoice &&
                isMedicalOrPrimaryCare &&
                !preferencesData?.isLoading && (
                  <Flex
                    css={{
                      ...smartChoiceFlex(
                        isMobileScreen,
                        showMemberSelection && displayMemberSelectorOptions,
                        isMixedResultsPage,
                        enableMapViewEnhancements && resultsPage,
                        showChoosePCPHeader
                      ),
                      position: 'absolute',
                    }}
                  >
                    {preferencesData?.data?.recommendationPreferencesStatus
                      ?.hasDismissedCoachMarkWeb === 'y' ||
                    isMobileScreen ? null : (
                      <Popover
                        align="center"
                        content={t('SMART_CHOICE.COACHMARK_TITLE') as string}
                        css={{
                          '& button span': { color: '$gray7 !important' },
                          '& .abyss-popover-header h2': { fontSize: '16px' },
                        }}
                        title={t('SMART_CHOICE.COACHMARK_CONTENT') as string}
                        width={400}
                      >
                        <IconSymbol
                          color="$primary1"
                          icon="settings"
                          size={24}
                          variant="outlined"
                        />
                      </Popover>
                    )}
                    <LinkContainer
                      before={
                        preferencesData?.data?.recommendationPreferencesStatus
                          ?.hasDismissedCoachMarkWeb === 'y' ||
                        isMobileScreen ? (
                          <IconSymbol
                            color="$primary1"
                            icon="settings"
                            size={24}
                            variant="outlined"
                          />
                        ) : null
                      }
                      css={{ ...smartChoiceLinkStyle(isMobileScreen) }}
                      data-auto-testid="smart-choice-button"
                      data-testid="smart-choice-button"
                      icon={
                        preferencesData?.data?.recommendationPreferencesStatus
                          ?.hasDismissedCoachMarkWeb === 'y'
                          ? ''
                          : 'circle'
                      }
                      iconSize="20"
                      onClick={onClickSmartChoiceHandler}
                      size={18}
                      text={
                        !isMobileScreen ? t('SMART_CHOICE.SMART_CHOICE') : null
                      }
                    />
                  </Flex>
                )}
            </Layout.Group>

            {enableMapViewEnhancements && resultsPage ? null : (
              <DemoHeader
                form={form}
                lob={lob}
                onMemberDataChange={onMemberDataChange}
                onMemberIdChange={onMemberIdChange}
                providerType={providerType}
                searchType={searchType}
                setLob={setLob}
                setMemberProfiles={setMemberProfiles}
                setProviderType={setProviderType}
                setSearchType={setSearchType}
              />
            )}
            <SearchBar
              breadcrumbs={breadcrumbs}
              chipsToTabsEnabled={chipsToTabsEnabled}
              choosePCPHeader={showChoosePCPHeader}
              isMixedResultsPage={isMixedResultsPage}
              isSmartChoiceIconVisible={
                ispsxProviderRecommendationEnabled &&
                isMedicalOrPrimaryCare &&
                !(showMemberSelection && displayMemberSelectorOptions)
              }
              memberLocation={memberLocation}
              showResultsPageForMap={enableMapViewEnhancements && resultsPage}
              showSearchInputBackButton={showSearchInputBackButton}
            />
            {showChips && chipsEnabled && (
              <Layout.Stack grow space={0}>
                <ChipsCategory
                  chips={filteredChips}
                  customMessage={customMessage}
                  data-auto-testid="search-home-chips-category"
                  data-testid="search-home-chips-category"
                  isMixedResultsPage={isMixedResultsPage}
                />
              </Layout.Stack>
            )}
          </Layout.Stack>
        </FormProvider>
      </PSXHeaderCardWrapper>
      {showChips && chipsToTabsEnabled && !isMixedResultsPage && (
        <Layout.Stack css={{ paddingBottom: '8px' }} grow space={0}>
          <TabsCategory
            chips={filteredChips}
            customMessage={customMessage}
            data-auto-testid="search-home-tabs-category"
            data-testid="search-home-tabs-category"
            isMixedResultsPage={isMixedResultsPage}
          />
        </Layout.Stack>
      )}

      {showOnboardingForm && (
        <OnboardingForm
          handleCloseDrawer={() => setShowOnboardingForm(false)}
          handleOpenSetPreferencesModal={() => setPreferencesModalOpen(true)}
          isOpen={showOnboardingForm}
        />
      )}
      {isPreferencesModalOpen && (
        <PreferencesForm
          handleCloseDrawer={() => setPreferencesModalOpen(false)}
          isOpen={isPreferencesModalOpen}
          preferencesData={
            preferencesData.data?.recommendationPreferencesStatus
          }
        />
      )}
    </React.Fragment>
  );
};
