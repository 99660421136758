/**
 * CONSTANTS
 */

import { HoursOfOperation } from '../models/HoursOfOperation';

export enum LineOfBusiness {
  EI = 'EI',
  MR = 'MR',
  OBH = 'OBH',
  CSP = 'CSP',
  CNS = 'CS',
  IFP = 'IFP',
}

export const COVERAGE_TYPES = {
  M: 'MEDICAL',
  B: 'BEHAVIORAL',
  V: 'VISION',
  D: 'DENTAL',
};

export const APIConstants = {
  INCLUDE_GRAPHQL_QUERIES: [
    'ProviderSearch',
    'SnackCardProviderSearch',
    'ProviderFilterCount',
  ],
  URGENT_CARE: 'URGENT_CARE',
  PES: {
    NAME: 'name',
    SPECIALTY: 'speciality',
    LOCATION: 'location',
    CAP_LIMIT: 9500, // Currently, PES Search support only records till offset of 9500.
  },
  PROVIDER_TYPES: {
    practitioner: 'practitioner',
    organization: 'organization',
    medical_group: 'Medical_Group',
    providerGroup: 'Provider Group',
    facility: 'Facility',
  },
  COVERAGE_TYPES: {
    MEDICAL: 'M',
    BEHAVIORAL: 'B',
    VISION: 'V',
    DENTAL: 'D',
  },
  MEMBER_RELEVANCY_CONFIG: {
    ROLLUPCODES_FEMALE_SPECIALITIES: 'ROLLUPCODES_FEMALE_SPECIALITIES',
    ROLLUPCODES_UNDER21_SPECIALITIES: 'ROLLUPCODES_UNDER21_SPECIALITIES',
    ROLLUPCODES_ABOVE21_SPECIALITIES: 'ROLLUPCODES_ABOVE21_SPECIALITIES',
  },
  DEFAULT_ROLLUP_CODES: {
    EXCLUDE_CODES: {
      ORGANIZATION: ['52-526-050', '52-526-033'],
      ORGANIZATION_ORG_TYPE_CODES: ['050', '033'],
    },
    INCLUDE_CODES: {
      MEDICAL_GROUP: ['050', '033'],
      URGENT_CARES: ['50-524-057', '50-524-030', '50-504-057', '50-504-030'],
    },
    URGENT_CARE_ORG_TYPE_CODES: [''],
  },
  MEMBER_RELEVANCY_CODES: {
    ROLLUPCODES_FEMALE_SPECIALITIES: {
      PRACTITIONER:
        '02-033-250,01-051-250,02-033-230,01-051-230,02-033-251,01-051-251,02-026-069,02-033-069,01-051-069',
      MEDICAL_GROUP:
        '52-526-033-033-250,52-526-033-051-250,52-526-033-033-230,52-526-033-051-230,52-526-033-033-251,52-526-033-051-251,52-526-033-026-069,52-526-033-033-069,52-526-033-051-069,' +
        '52-526-050-033-250,52-526-050-051-250,52-526-050-033-230,52-526-050-051-230,52-526-050-033-251,52-526-050-051-251,52-526-050-026-069,52-526-050-033-069,52-526-050-051-069',
      ORGANIZATION:
        '02-033-250,01-051-250,02-033-230,01-051-230,02-033-251,01-051-251,02-026-069,02-033-069,01-051-069',
    },
    ROLLUPCODES_UNDER21_SPECIALITIES: {
      PRACTITIONER:
        '02-043-037,01-051-037,02-043-258,01-051-258,02-044-079,01-051-079,02-043-274,02-044-274,01-051-274,02-030-389,02-031-389,02-043-389,01-051-389,' +
        '02-015-008,01-051-008,02-015-339,02-030-339,02-031-339,01-051-339,02-015-275,01-051-275,02-017-001,01-051-001,02-017-272,01-051-272,02-048-338,01-051-338,02-031-077,01-051-077,02-030-077',
      MEDICAL_GROUP:
        '52-526-033-043-037,52-526-033-051-037,52-526-033-043-258,52-526-033-051-258,52-526-033-044-079,52-526-033-051-079,52-526-033-043-274,52-526-033-044-274,52-526-033-051-274,52-526-033-030-389,52-526-033-031-389,52-526-033-043-389,52-526-033-051-389,' +
        '52-526-050-043-037,52-526-050-051-037,52-526-050-043-258,52-526-050-051-258,52-526-050-044-079,52-526-050-051-079,52-526-050-043-274,52-526-050-044-274,52-526-050-051-274,52-526-050-030-389,52-526-050-031-389,52-526-050-043-389,52-526-050-051-389,' +
        '52-526-033-015-008,52-526-033-051-008,52-526-033-015-339,52-526-033-030-339,52-526-033-031-339,52-526-033-051-339,52-526-033-015-275,52-526-033-051-275,52-526-033-017-001,52-526-033-051-001,52-526-033-017-272,52-526-033-051-272,52-526-033-048-338,52-526-033-051-338,52-526-033-031-077,52-526-033-051-077,52-526-033-030-077,' +
        '52-526-050-015-008,52-526-050-051-008,52-526-050-015-339,52-526-050-030-339,52-526-050-031-339,52-526-050-051-339,52-526-050-015-275,52-526-050-051-275,52-526-050-017-001,52-526-050-051-001,52-526-050-017-272,52-526-050-051-272,52-526-050-048-338,52-526-050-051-338,52-526-050-031-077,52-526-050-051-077,52-526-050-030-077',
      ORGANIZATION:
        '02-043-037,01-051-037,02-043-258,01-051-258,02-044-079,01-051-079,02-043-274,02-044-274,01-051-274,02-030-389,02-031-389,02-043-389,01-051-389,' +
        '02-015-008,01-051-008,02-015-339,02-030-339,02-031-339,01-051-339,02-015-275,01-051-275,02-017-001,01-051-001,02-017-272,01-051-272,02-048-338,01-051-338,02-031-077,01-051-077,02-030-077',
    },
    ROLLUPCODES_ABOVE21_SPECIALITIES: {
      PRACTITIONER:
        '02-015-008,01-051-008,02-015-339,02-030-339,02-031-339,01-051-339,02-015-275,01-051-275,02-017-001,01-051-001,02-017-272,01-051-272,02-048-338,01-051-338,02-031-077,01-051-077,02-030-077,' +
        '02-024-011,01-051-011',
      PRACTITIONER_MR: '01-019-038',
      MEDICAL_GROUP:
        '52-526-033-015-008,52-526-033-051-008,52-526-033-015-339,52-526-033-030-339,52-526-033-031-339,52-526-033-051-339,52-526-033-015-275,52-526-033-051-275,52-526-033-017-001,52-526-033-051-001,52-526-033-017-272,52-526-033-051-272,52-526-033-048-338,52-526-033-051-338,52-526-033-031-077,52-526-033-051-077,52-526-033-030-077,' +
        '52-526-050-015-008,52-526-050-051-008,52-526-050-015-339,52-526-050-030-339,52-526-050-031-339,52-526-050-051-339,52-526-050-015-275,52-526-050-051-275,52-526-050-017-001,52-526-050-051-001,52-526-050-017-272,52-526-050-051-272,52-526-050-048-338,52-526-050-051-338,52-526-050-031-077,52-526-050-051-077,52-526-050-030-077,' +
        '52-526-033-024-011,52-526-033-051-011,52-526-050-024-011,52-526-050-051-011',
      MEDICAL_GROUP_MR: '52-526-050-019-038,52-526-033-019-038',
      ORGANIZATION:
        '02-015-008,01-051-008,02-015-339,02-030-339,02-031-339,01-051-339,02-015-275,01-051-275,02-017-001,01-051-001,02-017-272,01-051-272,02-048-338,01-051-338,02-031-077,01-051-077,02-030-077,' +
        '02-024-011,01-051-011',
      ORGANIZATION_MR: '01-019-038',
    },
  },

  NEW_MEMBER_RELEVANCY_CODES: {
    ROLLUPCODES_FEMALE_SPECIALITIES: {
      PRACTITIONER:
        '02-043-037,01-051-037,02-043-258,01-051-258,02-044-079,01-051-079,02-043-274,02-044-274,01-051-274,02-030-389,02-031-389,02-043-389,01-051-389,' +
        '02-015-008,01-051-008,02-015-339,02-030-339,02-031-339,01-051-339,02-015-275,01-051-275,02-017-001,01-051-001,02-017-272,01-051-272,02-048-338,01-051-338,02-031-077,01-051-077,02-030-077,01-051-375,02-030-375,02-031-375' +
        '02-033-250,01-051-250,02-033-230,01-051-230,02-033-251,01-051-251,02-026-069,02-033-069,01-051-069',
      MEDICAL_GROUP:
        '52-526-033-043-037,52-526-033-051-037,52-526-033-043-258,52-526-033-051-258,52-526-033-044-079,52-526-033-051-079,52-526-033-043-274,52-526-033-044-274,52-526-033-051-274,52-526-033-030-389,52-526-033-031-389,52-526-033-043-389,52-526-033-051-389,' +
        '52-526-050-043-037,52-526-050-051-037,52-526-050-043-258,52-526-050-051-258,52-526-050-044-079,52-526-050-051-079,52-526-050-043-274,52-526-050-044-274,52-526-050-051-274,52-526-050-030-389,52-526-050-031-389,52-526-050-043-389,52-526-050-051-389,' +
        '52-526-033-015-008,52-526-033-051-008,52-526-033-015-339,52-526-033-030-339,52-526-033-031-339,52-526-033-051-339,52-526-033-015-275,52-526-033-051-275,52-526-033-017-001,52-526-033-051-001,52-526-033-017-272,52-526-033-051-272,52-526-033-048-338,52-526-033-051-338,52-526-033-031-077,52-526-033-051-077,52-526-033-030-077,52-526-033-051-375,52-526-033-030-375,52-526-033-031-375' +
        '52-526-050-015-008,52-526-050-051-008,52-526-050-015-339,52-526-050-030-339,52-526-050-031-339,52-526-050-051-339,52-526-050-015-275,52-526-050-051-275,52-526-050-017-001,52-526-050-051-001,52-526-050-017-272,52-526-050-051-272,52-526-050-048-338,52-526-050-051-338,52-526-050-031-077,52-526-050-051-077,52-526-050-030-077,52-526-050-051-375,52-526-050-030-375,52-526-050-031-375' +
        '52-526-033-033-250,52-526-033-051-250,52-526-033-033-230,52-526-033-051-230,52-526-033-033-251,52-526-033-051-251,52-526-033-026-069,52-526-033-033-069,52-526-033-051-069,' +
        '52-526-050-033-250,52-526-050-051-250,52-526-050-033-230,52-526-050-051-230,52-526-050-033-251,52-526-050-051-251,52-526-050-026-069,52-526-050-033-069,52-526-050-051-069',
      ORGANIZATION:
        '02-043-037,01-051-037,02-043-258,01-051-258,02-044-079,01-051-079,02-043-274,02-044-274,01-051-274,02-030-389,02-031-389,02-043-389,01-051-389,' +
        '02-015-008,01-051-008,02-015-339,02-030-339,02-031-339,01-051-339,02-015-275,01-051-275,02-017-001,01-051-001,02-017-272,01-051-272,02-048-338,01-051-338,02-031-077,01-051-077,02-030-077,01-051-375,02-030-375,02-031-375' +
        '02-033-250,01-051-250,02-033-230,01-051-230,02-033-251,01-051-251,02-026-069,02-033-069,01-051-069',
    },
    ROLLUPCODES_UNDER21_SPECIALITIES: {
      PRACTITIONER:
        '02-043-037,01-051-037,02-043-258,01-051-258,02-044-079,01-051-079,02-043-274,02-044-274,01-051-274,02-030-389,02-031-389,02-043-389,01-051-389,' +
        '02-015-008,01-051-008,02-015-339,02-030-339,02-031-339,01-051-339,02-015-275,01-051-275,02-017-001,01-051-001,02-017-272,01-051-272,02-048-338,01-051-338,02-031-077,01-051-077,02-030-077,01-051-375,02-030-375,02-031-375',
      MEDICAL_GROUP:
        '52-526-033-043-037,52-526-033-051-037,52-526-033-043-258,52-526-033-051-258,52-526-033-044-079,52-526-033-051-079,52-526-033-043-274,52-526-033-044-274,52-526-033-051-274,52-526-033-030-389,52-526-033-031-389,52-526-033-043-389,52-526-033-051-389,' +
        '52-526-050-043-037,52-526-050-051-037,52-526-050-043-258,52-526-050-051-258,52-526-050-044-079,52-526-050-051-079,52-526-050-043-274,52-526-050-044-274,52-526-050-051-274,52-526-050-030-389,52-526-050-031-389,52-526-050-043-389,52-526-050-051-389,' +
        '52-526-033-015-008,52-526-033-051-008,52-526-033-015-339,52-526-033-030-339,52-526-033-031-339,52-526-033-051-339,52-526-033-015-275,52-526-033-051-275,52-526-033-017-001,52-526-033-051-001,52-526-033-017-272,52-526-033-051-272,52-526-033-048-338,52-526-033-051-338,52-526-033-031-077,52-526-033-051-077,52-526-033-030-077,52-526-033-051-375,52-526-033-030-375,52-526-033-031-375' +
        '52-526-050-015-008,52-526-050-051-008,52-526-050-015-339,52-526-050-030-339,52-526-050-031-339,52-526-050-051-339,52-526-050-015-275,52-526-050-051-275,52-526-050-017-001,52-526-050-051-001,52-526-050-017-272,52-526-050-051-272,52-526-050-048-338,52-526-050-051-338,52-526-050-031-077,52-526-050-051-077,52-526-050-030-077,52-526-050-051-375,52-526-050-030-375,52-526-050-031-375',
      ORGANIZATION:
        '02-043-037,01-051-037,02-043-258,01-051-258,02-044-079,01-051-079,02-043-274,02-044-274,01-051-274,02-030-389,02-031-389,02-043-389,01-051-389,' +
        '02-015-008,01-051-008,02-015-339,02-030-339,02-031-339,01-051-339,02-015-275,01-051-275,02-017-001,01-051-001,02-017-272,01-051-272,02-048-338,01-051-338,02-031-077,01-051-077,02-030-077,01-051-375,02-030-375,02-031-375',
    },
    ROLLUPCODES_ABOVE21_SPECIALITIES: {
      PRACTITIONER:
        '02-015-008,01-051-008,02-015-339,02-030-339,02-031-339,01-051-339,02-015-275,01-051-275,02-017-001,01-051-001,02-017-272,01-051-272,02-048-338,01-051-338,02-031-077,01-051-077,02-030-077,02-024-011,01-051-011,01-019-038,02-019-038,01-051-375,02-030-375,02-031-375',
      MEDICAL_GROUP:
        '52-526-033-015-008,52-526-033-051-008,52-526-033-015-339,52-526-033-030-339,52-526-033-031-339,52-526-033-051-339,52-526-033-015-275,52-526-033-051-275,52-526-033-017-001,52-526-033-051-001,52-526-033-017-272,52-526-033-051-272,52-526-033-048-338,52-526-033-051-338,52-526-033-031-077,52-526-033-051-077,52-526-033-030-077,' +
        '52-526-050-015-008,52-526-050-051-008,52-526-050-015-339,52-526-050-030-339,52-526-050-031-339,52-526-050-051-339,52-526-050-015-275,52-526-050-051-275,52-526-050-017-001,52-526-050-051-001,52-526-050-017-272,52-526-050-051-272,52-526-050-048-338,52-526-050-051-338,52-526-050-031-077,52-526-050-051-077,52-526-050-030-077,' +
        '52-526-033-024-011,52-526-033-051-011,52-526-050-024-011,52-526-050-051-011,52-526-033-019-038,52-526-050-019-038,52-526-033-051-375,52-526-050-051-375,52-526-033-030-375,52-526-050-030-375,52-526-033-031-375,52-526-050-031-375',
      ORGANIZATION:
        '02-015-008,01-051-008,02-015-339,02-030-339,02-031-339,01-051-339,02-015-275,01-051-275,02-017-001,01-051-001,02-017-272,01-051-272,02-048-338,01-051-338,02-031-077,01-051-077,02-030-077,02-024-011,01-051-011,01-019-038,02-019-038,01-051-375,02-030-375,02-031-375',
    },
  },

  OBAPI: {
    OXFORD: {
      SYSTEMTYPECODE: 'CR',
      MEMBERSHIPCATEGORY: 'OXFORD',
    },
    RALLYCONNECT: 'RALLYCONNECT',
    RECIPROCITYID: 'RECIPROCITYID',
    PRODUCTCODE: 'PRODUCTCODE',
    MARKETTYPE: 'MARKETTYPE',
    PROVIDERSEARCHCODE: 'PROVIDERSEARCHCODE',
    QUALITYTIERRULEID: 'QUALITYTIERRULEID',
    NETWORKID: 'NETWORKID',
    DDP: 'DDP',
    DVCN: 'DVCN',
    LAWW: 'LAWW',
    VISION: 'VISION',
    DENTAL: 'DENTAL',
    EHB_DENTAL: 'DENTALEMBEDDED',
    EHB_VISION: 'VISIONEMBEDDED',
    BEHAVIORAL: 'BEHAVIORAL',
    BEHAVIORAL_HEALTH: 'BEHAVIORAL HEALTH',
    MEDICAL: 'MEDICAL',
    ENI: 'EI',
    ADDRESS: {
      PERMANENT: 'PERMANENT',
      MAILING: 'MAILING',
    },
    COVERAGETYPECODE: {
      BEHAVIORAL: 'B',
      DENTAL: 'D',
      VISION: 'V',
      DVCN: 'DVCN',
      MEDICAL: 'M',
    },
    LINEOFBUSINESS: {
      'E&I': LineOfBusiness.EI,
      'M&R': LineOfBusiness.MR,
      OBH: LineOfBusiness.OBH,
      CSP: LineOfBusiness.CSP,
      'C&S': LineOfBusiness.CNS,
      IFP: LineOfBusiness.IFP,
    },
  },
  Primary_Care: {
    Actions: {
      VIEW: 'VIEW',
      UPDATE: 'UPDATE',
    },
  },
  HEADERS: {
    CONTENT_TYPE: 'application/json',
  },
  RECENT_VISIT_CATEGORIES: {
    PRIMARY_CARE: 'primaryCare',
    MEDICAL_SPECIALISTS: 'medicalSpecialists',
    HEALTH_SUPPLIES: 'healthSupplies',
    FACILITIES: 'facilities',
    VISION: 'vision',
    DENTAL: 'dental',
  },
  LANGUAGE: {
    PSLOCALE: 'PS_Locale',
  },
};

export const AcceptingPatientStatus = {
  YES: 'Accepting new patients',
  NO: 'Not accepting new patients',
};

export const msgWhenNoDataPresent = {
  msg: 'Sorry, there was an internal error retrieving data, please try again.',
};

export const tierOneContent = {
  msg: 'Members may pay less when they visit providers identified with the Tier 1 symbol. When a member chooses a Tier 1 provider, they’re receiving value from their benefit plan and are often saving money.',
};

export const NETWORK_STATUS = {
  IN: 'In-network',
  OUT: 'Out-of-network',
};

export const ResultType = {
  LIST: 'List',
  DETAILS: 'Details',
};

export enum CareCategories {
  ALL = 'All',
  BEHAVIORAL_HEALTH = 'Behavioral Health',
  MEDICAL_GROUPS = 'Medical Groups',
  MEDICAL_SPECIALISTS = 'Medical Specialists',
  FACILITIES = 'Facilities',
  HEALTH_SUPPLIES = 'Health Supplies',
  PRIMARY_CARE = 'Primary Care',
  DENTAL = 'Dental',
  VISION = 'Vision',
}

export enum ChipCategories {
  ALL = 'all',
  BEHAVIORAL_HEALTH = 'behavioral-health',
  DENTAL = 'dental',
  FACILITIES = 'labs-and-services',
  MEDICAL_SPECIALIST = 'medical-specialists',
  HEALTH_SUPPLIES = 'health-supplies',
  PRIMARY_CARE = 'primary-care',
  VISION = 'vision',
}

export const L2_CATEGORIES = {
  '01': CareCategories.PRIMARY_CARE,
  '02': CareCategories.MEDICAL_SPECIALISTS,
  '03': CareCategories.BEHAVIORAL_HEALTH,
  '04': CareCategories.DENTAL,
  '05': CareCategories.VISION,
  '50': CareCategories.FACILITIES,
  '51': CareCategories.HEALTH_SUPPLIES,
  '53': CareCategories.FACILITIES,
};

export const TranslationFiles = {
  DEGREE: 'degreeTranslations',
  PES_SPECIALITY: 'pesSpecialityTranslations',
  COST_EXPERIENCE_CARE_PATHS: 'costExperienceCarePathsTranslations',
};

/**
 * INTERFACES / TYPES
 */
export interface DirectoryPDFProvider {
  numberOfReviews: String;
  providerId: String;
  providerName: String;
  providerType: String;
  acceptingNewPatients: Boolean;
  primaryDegrees: String[];
  specialities: String[];
  healthGradeRating: Number;
  languagesSpoken: String[];
  distance: Number;
  address: Address;
  networkStatus: String;
}

export interface ProviderDetailsResponse {
  providerId: String;
  providerName: String;
  gender: String;
  professionalDesignation: String;
  primarySpeciality: [String];
  providerLocations: [ProviderLocationsResponse];
  UHPDRating: String;
  accreditations: [String];
  accessibility: [String];
  hospitalAffiliationList: [HospitalAffiliation];
  healthGradeRating: String;
  education: [Education];
  providerSpecialities: [String];
  providerType: String;
  otherSpecialities: [String];
  boardCertifications: [String];
  servicesProvided: [String];
  networkStartDate: String;
  networkEndDate: String;
  networkStatus: String;
  otherProviderDetails: [String];
  lastUpdatedDate: String;
  website: String;
  organizationType: [String];
  lastVerifiedDate: String;
  languagesSpoken: [String];
  providerGroups: [String];
  preventingMedicationErrors: String;
  rateOfEpisiotomy: String;
  managingSeriousErrors: String;
  reduceCLInfectionsInICUs: String;
  highRiskDeliveries: String;
  qualityOutcomeTypeCode: String;
  efficiencyOutcomeTypeCode: String;
  biography: String;
  primaryDegrees: [String];
}
export interface ProviderLocationsResponse {
  locationId: String;
  latitude: String;
  longitude: String;
  hoursOfOperations: [HoursOfOperation];
  distance: String;
  emailAddress: [String];
  phones: PhoneNumbers;
  defaultLocation: Boolean;
  acceptingNewPatients: Boolean;
  virtualCareOffered: Boolean;
  offersEveningAppointment: Boolean;
  website: String;
  specialities: [String];
  digestId: String;
  pcpId: String;
  networkStartDate: String;
  networkEndDate: String;
  address: Address;
  servicesOffered?: string[];
  medicalInterpreterInOffice?: boolean;
  languageLineAvailable?: boolean;
  methodsOfService?: string[];
  publicTransportationMethods?: string[];
  isSedationOffered: boolean;
  electronicPrescribingOffered: boolean;
}
export interface Education {
  school: String;
  degrees: [String];
}
export interface HospitalAffiliation {
  name: String;
  facilityId: String;
  imageId: String;
}
export interface Address {
  line: string[];
  city: string;
  district?: string;
  state: string;
  postalCode: string;
}
export interface PhoneNumbers {
  phone: [String];
  emergency: [String];
  appointment: [String];
  fax: [String];
}

export interface Hours {
  sunday: string;
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
}

export const SortBy = {
  BEST_MATCH: 'BEST_MATCH',
};

export const TypeAheadCoverageTypeMapping = {
  md: 'M',
  bh: 'B',
  vn: 'V',
  dt: 'D',
  mdox: 'M',
  mdcosmos: 'M',
  mddsnp: 'M',
  mdcns: 'M',
  mdifp: 'M',
};

export const TypeaheadVisionMapping = {
  FACILITY: 'Facility',
  VISION: 'Vision',
  vn: 'vn',
  VISION_ROLLUPCODE: '52-526-033',
  OPTICIAN: 'Optician',
};
export const Localization = {
  DEFAULT_LOCALE: 'en-US',
};

export const TranslationType = {
  SPECIALTY: 'specialty',
  DEGREE: 'degree',
  AREA_OF_EXPERTISE: 'aoe',
  CULTURAL_COMPETENCY: 'culturalCompetency',
  CULTURAL_COMPETENCY_CATEGORIES: 'culturalCompetencyCategories',
  LANGUAGE: 'language',
  ACCESSIBILITY: 'accessibility',
  PREFERRED_FACILITY: 'preferredFacility',
  PRACTICE_LIMITATION: 'practiceLimitation',
  VIRTUAL_CARE: 'virtualCare',
  VIRTUAL_CARE_OPTIONS: 'virtualCareOptions',
  FACILITY_SERVICE_CODE: 'medicalEquipment',
  DDP: 'ddp',
  FACILITY_SUB_CATEGORY_CODE: 'organizationType',
  LEAPFROG_RATING: 'leapFrogRating',
  BEHAVIORAL_PROGRAM: 'behavioralProgram',
  ETHNICITY: 'ethnicity',
  RACE: 'race',
  AUTO_COMPLETE: 'autoComplete',
  SERVICES_OFFERED: 'servicesOffered',
  ACCOMMODATE_OFFERED: 'accommodateOffered',
  METHODS_OF_SERVICE: 'methodsOfService',
  CARE_PATH_STEPS: 'carePathSteps',
  BEHAVIORAL_FACILITY_PROGRAMS: 'behavioralFacilityPrograms',
  PLATINUM_PROGRAMS: 'platinumPrograms',
  DOC_ASAP_AVAILABILITY: 'docAsapAvailability',
  SERVICES_PROVIDED: 'servicesProvided',
  HCBS_WAIVERS: 'hcbsWaivers',
};

export enum MethodsOfService {
  CODE1 = 'Mobile Clinic (call for location information)',
  CODE2 = 'Online Scheduling',
  CODE3 = 'In-Office Telemedicine',
  CODE4 = 'Online Store',
}

export enum PublicTransportationMethods {
  Bus = '1',
  Boat = '2',
  Train = '3',
  Subway = '4',
  Yes = 'Yes',
  No = 'No',
}

export enum PopulationType {
  UNET = 'UNET',
  USP = 'USP',
  COSMOS = 'COSMOS',
  IFP = 'IFP',
  IFP_BINDER = 'IFP Binder',
  MEDICAID = 'Medicaid',
  DSNP = 'DSNP',
  UNKNOWN = 'UNKNOWN',
  UM = 'UM',
  SIERRA = 'SIERRA',
}

export const VISION_DETAILS_API_CONSTANTS = {
  MethodsOfService,
  PublicTransportationMethods,
};

export const preferredFacilitiesPseudoRollupCodes = [
  '50-999-010',
  '50-999-020',
  '50-999-030',
];

export const FEMALE_SPECIALITIES_MINIMUM_AGE = 13;
export const FEMALE_SPECIALITIES_MAXIMUM_AGE = 20;
export const VIRTUAL_BH_PSEUDO_ROLLUP_CODE = '03-999-100';
export const EAP_BH_FILTER_NAME = 'acceptsEap';

export const MEDICAL_COVERAGE = ['M', 'ME', 'MD', 'MA', 'MAPD'];
export enum PortalSource {
  MEDICARE = 'medicare',
  UMR = 'umr',
}
export enum LOB {
  MNR = 'M&R',
  EI = 'E&I',
  CNS = 'C&S',
  IFP = 'IFP',
}
export const ProductIdToInclude = [
  APIConstants.OBAPI.DENTAL,
  APIConstants.OBAPI.VISION,
  APIConstants.OBAPI.EHB_DENTAL,
  APIConstants.OBAPI.EHB_VISION,
  APIConstants.OBAPI.BEHAVIORAL,
  APIConstants.OBAPI.DVCN,
];
export const plnPseudoCode = '50-999-020';
export const ecpPseudoCode = '02-999-200';
export const MEMBERSHIP_CATEGORY = 'PUBLIC EXCHANGE';
export const MEMBERSHIP_CATEGORY_PEND = 'PUBLIC EXCHANGE PEND';
export const COSMOS_NETWORK_IDS = [
  1, 2, 12, 15, 16, 19, 20, 21, 25, 26, 29, 30, 31, 34, 35, 36, 37, 38, 39, 41,
  42, 43, 45, 46, 47, 48, 49, 52, 56, 57, 59, 60, 62, 66, 67, 71, 72, 75, 76,
  78, 79, 105, 106, 107, 108, 110, 111, 112, 113, 114, 115, 116, 119, 120, 122,
  123, 124, 125, 126, 127, 128, 129, 130, 142, 143, 144, 145, 146, 147, 148,
  149,
];
export const DSNP_NETWORK_IDS = [
  203, 210, 216, 220, 221, 226, 235, 245, 256, 264, 271, 277, 278, 279, 281,
  290, 306, 321, 361, 402, 421, 431, 506, 529, 602, 621, 708, 717, 718, 723,
  728, 729, 731, 830,
];
export enum CoverageSNPTypes {
  'DSNP' = 'DSNP',
  'COSMOS' = 'COSMOS',
}
export const RecommendationScoreWeightage = {
  Quality: 30,
  Benefits: 25,
  Convenience: 20,
  PersonalFit: 20,
  Verification: 4.95,
  Randomization: 0.05,
};

export enum RecommendationScoreMatch {
  Low = 'Low',
  Good = 'Good',
  Excellent = 'Excellent',
}

export enum RecommendationCriteriaMatch {
  None = 'None',
  Some = 'Some',
  All = 'All',
}

export const IFP_TIERED_NETWORK = '915';

export const PORTAL_NAME = {
  LAWW: 'LAWW',
  SUPPORT_FINDER: 'SUPPORT_FINDER',
};
export const accomodateOffered = 'accommodateOffered';

export const TYPEAHEAD_SEARCH_TEXTS = {
  HCBS: 'home and community based services',
};
