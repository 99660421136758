import { storage } from '@abyss/web/tools/storage';

import {
  CareCategories,
  Constants,
  CoverageSNPTypes,
  CoverageTypesCodes,
  LOB,
  MemberType,
  PopulationType,
  ProviderTypeShortNames,
  ProviderTypes,
  RelationshipTypeCodes,
  ReverseCoverageTypesCodes,
  ReverseDentalCodes,
  ReverseVisionCodes,
} from '../common/Constants';
import { ConstantsLagoon } from '../common/ConstantsLagoon';
import { MemberInfo } from '../common/Portal/PortalMemberInfo';
import { getFeatureFlag } from '../common/Utils';
import { isMnrMember } from '../common/Utils/memberUtils/memberUtils';
import { StoreKeys } from '../hooks/useStore/state';
import { useStore } from '../hooks/useStore/useStore';
import { CustomPreferredPolicy } from '../models/Lagoon';
import { DateTime } from '../utils/DateTime';
import { removeLeadingZeroes } from './string.utils';
import isEmpty from 'lodash/isEmpty';

export const getCurrentMember = () => {
  const isGuest = storage.session.get(Constants.STORAGE_KEYS.SESSION.IS_GUEST);
  const guestUser = isGuest
    ? storage.session.get(Constants.STORAGE_KEYS.SESSION.GUEST_USER)
    : {};
  if (isGuest) {
    return guestUser;
  }
  const currentIndex =
    storage.session.get(Constants.STORAGE_KEYS.SESSION.ACTIVE_MEMBER_INDEX) ||
    0;

  const memberInfo = useStore(StoreKeys.OBAPI_MEMBERS);
  return memberInfo?.[currentIndex] ?? memberInfo?.[0];
};

export const getMemberType = (member: {
  lineOfBusiness: LOB;
  isIFPMember: boolean;
}) => {
  if (member.isIFPMember) {
    return MemberType.IFP;
  }
  if (member.lineOfBusiness === LOB.MNR) {
    return MemberType.MNR;
  }
  if (member.lineOfBusiness === LOB.ENI) {
    return MemberType.ENI;
  }
  if (member.lineOfBusiness === LOB.CNS) {
    return MemberType.CNS;
  }

  // fallback as ENI member
  return MemberType.ENI;
};

export const memberHasBHCoverage = (memberCoverages) =>
  memberCoverages?.find((cov) => cov === 'B') == 'B';

export const getCurrentMemberIsPreEffective = () =>
  getCurrentMember()?.isPreEffective;

export const getIsCurrentMemberSubscriber = () => {
  const currentMember = getCurrentMember();
  const memberRelationshipTypecode =
    currentMember?.demographics?.relationshipTypeCode?.code;
  return memberRelationshipTypecode === RelationshipTypeCodes.SUBSCRIBER;
};

export const isMnrCosmosMember = (coverageTypeCode) => {
  const currentMember = getCurrentMember();
  const { eligibilitySystemTypeCode, claimSystemTypeCode } =
    currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.find(
      (coverage) => coverage.typeCode?.coverageTypeCode === coverageTypeCode
    ) || {};
  return eligibilitySystemTypeCode === 'MR' && claimSystemTypeCode === '02';
};

export const isLoggedInMember = () =>
  getCurrentMember() === getLoggedInMember();

export const getDependentInfo = (dependentSeqNbr) => {
  const memberInfo = useStore(StoreKeys.OBAPI_MEMBERS);
  return memberInfo?.find(
    (member) => member?.demographics.dependentSeqNbr === dependentSeqNbr
  );
};

export const fetchMemberStateFromMapBox = (longitude, latitude, mapKey) =>
  fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?types=address&&access_token=${mapKey}`,
    { method: 'GET' }
  ).then((resp) => resp.json());

export const getLoggedInMember = () => {
  const loggedInMemberIndex =
    storage.session.get(
      Constants.STORAGE_KEYS.SESSION.LOGGED_IN_MEMBER_INDEX
    ) || 0;
  const memberInfo = useStore(StoreKeys.OBAPI_MEMBERS);
  return memberInfo?.[loggedInMemberIndex];
};

export const getCoverageTypes = (currentMember) =>
  currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.map(
    (coverage) => coverage?.typeCode?.coverageTypeCode
  );

export const isDsnpMember = (currentMember) => {
  const dsnpMember =
    currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType
      ?.map((coverage) => coverage?.snpType)
      .find((snpType) => snpType === CoverageSNPTypes.DSNP) ===
    CoverageSNPTypes.DSNP;
  return dsnpMember;
};

export const getSitusState = (currentMember, lineOfBusiness) => {
  const situsState =
    currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.[0]
      ?.situsState;
  return isCNSMember(lineOfBusiness) ? situsState : '';
};

export const isGroupMNRMember = (currentMember) => {
  const fundingArrangement =
    isMnrMember(currentMember?.lineOfBusiness) &&
    currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType
      ?.map((coverage) => coverage.fundingArrangement)
      .find((group) => group === 'G');
  return !!fundingArrangement;
};
export const getMemberCoverageTypeSitusState = (currentMember): string => {
  const coverageType =
    currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.[0];
  return coverageType?.situsState || '';
};
export const getActiveCoverages = (currentMember) => {
  const todayDate = DateTime.getCurrentDateTimeWithCentralOffset()
    .toISOString()
    .slice(0, 10);

  return currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType
    ?.map((coverageType) =>
      coverageType?.coverageEffDateRange?.endDate >= todayDate &&
      coverageType?.coverageEffDateRange?.startDate <= todayDate
        ? coverageType.typeCode.coverageTypeCode
        : null
    )
    .filter((coverageTypeCode) => coverageTypeCode !== null);
};

export const getCoverageEffDateRange = (
  currentMember: MemberInfo,
  coverageType: string
) => {
  const coverageData =
    currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.filter(
      (covType) => covType?.typeCode?.coverageTypeCode === coverageType
    );
  const startDate = coverageData?.[0]?.coverageEffDateRange?.startDate;
  const endDate = coverageData?.[0]?.coverageEffDateRange?.endDate;
  return { startDate, endDate };
};

export const getMarketTypes = (currentMember) =>
  currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.map(
    (coverage) => coverage.marketType
  );
export const getProductCode = (currentMember) =>
  currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.map(
    (coverage) => coverage.productCode
  );
export const getProductCodeForCoverageType = (
  currentMember,
  coverageTypeCode
) =>
  currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.find(
    (coverage) => coverage.typeCode?.coverageTypeCode === coverageTypeCode
  )?.productCode;

export const getPolicyNumber = (currentMember, coverageTypeCode) =>
  currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.find(
    (coverage) => coverage.typeCode?.coverageTypeCode === coverageTypeCode
  )?.policyNumber;

export const isTypeCodeMatchingCoverageType = (
  coverageType: string | undefined,
  memberCoverageTypeCode: string
) => {
  if (coverageType === 'V') {
    return ReverseVisionCodes.includes(memberCoverageTypeCode);
  }
  if (coverageType === 'D') {
    return ReverseDentalCodes.includes(memberCoverageTypeCode);
  }
  return memberCoverageTypeCode === (coverageType ?? 'M');
};

export const getPlanVariationCode = (currentMember, coverageTypeCode) =>
  currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.find(
    (coverage) =>
      isTypeCodeMatchingCoverageType(
        coverageTypeCode,
        coverage.typeCode?.coverageTypeCode
      )
  )?.planVariationCode;

export const getClaimOrEligibilitySystemTypeCode = (
  currentMember,
  coverageTypeCode
) =>
  currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.find(
    (coverage) =>
      isTypeCodeMatchingCoverageType(
        coverageTypeCode,
        coverage.typeCode?.coverageTypeCode
      )
  ) || {};

export const getReportingCode = (currentMember, coverageTypeCode) =>
  currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.find(
    (coverage) => coverage.typeCode?.coverageTypeCode === coverageTypeCode
  )?.reportingCode;

export const getCurrentMemberPCPInfo = (
  dependentSeqNbr,
  primaryCare,
  isPreEffective = false
) => {
  const primaryCareFuturePcpInfo = primaryCare?.find(
    ({ depSeqNbr, pcpInfo }) => depSeqNbr === dependentSeqNbr && !pcpInfo.hasPCP
  );
  if (isPreEffective && primaryCareFuturePcpInfo) {
    return primaryCareFuturePcpInfo?.pcpInfo.futurePCP;
  } else {
    return primaryCare?.find(
      ({ depSeqNbr, pcpInfo }) =>
        pcpInfo.hasPCP &&
        pcpInfo.isCoverageActive &&
        depSeqNbr === dependentSeqNbr
    )?.pcpInfo;
  }
};

export const getCurrentPlanYear = () =>
  String(DateTime.getCurrentDateTimeWithCentralOffset().getUTCFullYear());

export const getMemberEffectivePlanYear = (memberEffectiveDate: Date) =>
  String(memberEffectiveDate.getFullYear());

export const getMemberByDependentSeqNbr = (dependentSeqNbr) => {
  const memberInfo = useStore(StoreKeys.OBAPI_MEMBERS);
  const reqMember = memberInfo.filter(
    (member) => member.demographics.dependentSeqNbr === dependentSeqNbr
  );
  return reqMember[0];
};

export const getMemberPlanYear = (
  isPreEffective: boolean,
  currentMemberEffectiveDate: string
) => {
  const memberEffectiveYear = getMemberEffectivePlanYear(
    new Date(currentMemberEffectiveDate?.replace(/-/g, '/'))
  );
  const currentYear = getCurrentPlanYear();
  return isPreEffective ? memberEffectiveYear : currentYear;
};

export const getPreEffectiveDateforNetwork = (coverageType, isPreEffective) => {
  const { startDate = '' } = coverageType?.coverageEffDateRange || {};
  let preEffectiveDate = '';
  if (startDate && isPreEffective) {
    // convert 2024-10-01 to 20241001
    preEffectiveDate = `:${startDate.replaceAll('-', '')}`;
  }
  return preEffectiveDate;
};

const getCoverageTypeCodes = (
  currentMember,
  coverageTypeCodeMap,
  isPreEffective
) => {
  const {
    lineOfBusiness = '',
    reciprocityId: mdReciprocityId = '',
    isCnSMember,
    isCnSDsnpMember,
    isIFPMember,
  } = currentMember || {};
  const isMnrMember = lineOfBusiness == LOB.MNR;
  const todayDate = DateTime.getCurrentDateTimeWithCentralOffset()
    .toISOString()
    .slice(0, 10);

  return currentMember?.eligibility
    .flatMap((coverage) => coverage?.memberHealthCoverage?.coverageType)
    .filter(
      (coverageType) =>
        coverageTypeCodeMap[coverageType?.typeCode?.coverageTypeCode] !==
        undefined
    )
    .filter((coverageType) =>
      // if it's a pre-effective plan, do not filter out the coverages
      isPreEffective
        ? true
        : coverageType?.coverageEffDateRange?.endDate >= todayDate &&
          coverageType?.coverageEffDateRange?.startDate <= todayDate
    )
    .map((coverageType) => {
      const preEffectiveDate = getPreEffectiveDateforNetwork(
        coverageType,
        isPreEffective
      );
      const { coverageTypeCode = '', reciprocityId = '' } =
        coverageType?.typeCode || {};
      // append pre-effective date to all the network ids
      if (
        coverageTypeCode ===
        ReverseCoverageTypesCodes[CoverageTypesCodes.BEHAVIORAL_HEALTH]
      ) {
        if (isMnrMember) {
          return `bh_${reciprocityId}${preEffectiveDate}`;
        } else if (isCnSMember || isIFPMember) {
          return `bh_${reciprocityId}`;
        } else {
          return `${Constants.NETWORK_COVERAGE_CODE.BEHAVIORAL_B}${preEffectiveDate}`;
        }
      }
      if (coverageTypeCode === ReverseCoverageTypesCodes.MEDICAL) {
        const isSierra = currentMember?.population === PopulationType.SIERRA;
        const medicalCov = isSierra
          ? `mdsierra_${currentMember?.reciprocityId}${preEffectiveDate}%3B${Constants.NETWORK_COVERAGE_CODE.SIERRA_MEDICAL}${preEffectiveDate}`
          : `md_${currentMember?.reciprocityId}${preEffectiveDate}%3B${Constants.NETWORK_COVERAGE_CODE.MEDICAL}${preEffectiveDate}`;
        const preText =
          coverageType?.snpType === CoverageSNPTypes.DSNP || isCnSDsnpMember
            ? 'mddsnp'
            : isCnSMember
            ? 'mdcns'
            : isIFPMember
            ? 'mdifp'
            : 'mdcosmos';
        return isMnrMember || isCnSMember || isIFPMember
          ? mdReciprocityId &&
              mdReciprocityId
                ?.split(',')
                .map((val) => `${preText}_${val}${preEffectiveDate}`)
                .join('%3B')
          : medicalCov;
      }
      return `${coverageTypeCodeMap[coverageTypeCode]}${preEffectiveDate}`;
    });
};

const getGuestMemberCoverage = (currentMember) => {
  const guestMemberCoverage =
    currentMember?.eligibility[0]?.memberHealthCoverage?.coverageType[0]
      ?.typeCode?.coverageTypeCode;
  if (
    guestMemberCoverage ===
      ReverseCoverageTypesCodes[CoverageTypesCodes.BEHAVIORAL_CARVE_OUT] ||
    guestMemberCoverage ===
      ReverseCoverageTypesCodes[CoverageTypesCodes.BEHAVIORAL_HEALTH]
  ) {
    return `bh_${getNetworkIdForGuestUser()}`;
  }

  return null;
};
export const getMemberCoverage = (
  currentMember: any,
  featureFlags: { key: string; active: boolean }[]
) => {
  const isGuest = storage.session.get(Constants.STORAGE_KEYS.SESSION.IS_GUEST);
  if (isGuest) {
    return getGuestMemberCoverage(currentMember);
  }

  const isSuppressPreEffectiveFF: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.IS_SUPPRESS_PREEFFECTIVE
  );
  const isCurrentMemberPreEffective = currentMember?.isPreEffective === true;
  const isPreEffective =
    !isSuppressPreEffectiveFF && isCurrentMemberPreEffective;

  const visionChipEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.VISION_CHIP_ENABLED
  );
  const dentalChipEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DENTAL_CHIP_ENABLED
  );
  const visionNetworkId =
    currentMember?.networks?.filter(
      (coverage) =>
        ReverseCoverageTypesCodes[coverage?.type] ===
          ReverseCoverageTypesCodes.VISION ||
        ReverseCoverageTypesCodes[coverage?.type] ===
          ReverseCoverageTypesCodes.VISIONEMBEDDED
    )[0]?.value?.[0] || '';

  const dentalNetworkId =
    currentMember?.networks?.filter(
      (coverage) =>
        ReverseCoverageTypesCodes[coverage?.type] ===
          ReverseCoverageTypesCodes.DENTAL ||
        ReverseCoverageTypesCodes[coverage?.type] ===
          ReverseCoverageTypesCodes.DENTALEMBEDDED
    )[0]?.value?.[0] || '';

  const coverageTypeCodeMap = {
    [ReverseCoverageTypesCodes.MEDICAL]: '',
    [ReverseCoverageTypesCodes[CoverageTypesCodes.BEHAVIORAL_HEALTH]]: '',
    [ReverseCoverageTypesCodes[CoverageTypesCodes.BEHAVIORAL_CARVE_OUT]]: `bh_${
      currentMember?.bhReciprocityId || ''
    }`,
    [ReverseCoverageTypesCodes.VISION]:
      visionChipEnabled && visionNetworkId ? `vn_${visionNetworkId}` : '',
    [ReverseCoverageTypesCodes.DENTAL]:
      dentalChipEnabled && dentalNetworkId ? `dt_${dentalNetworkId}` : '',
    [ReverseCoverageTypesCodes.VISIONEMBEDDED]:
      visionChipEnabled && visionNetworkId ? `vn_${visionNetworkId}` : '',
    [ReverseCoverageTypesCodes.DENTALEMBEDDED]:
      dentalChipEnabled && dentalNetworkId ? `dt_${dentalNetworkId}` : '',
  };

  const coverageTypeCodes = getCoverageTypeCodes(
    currentMember,
    coverageTypeCodeMap,
    isPreEffective
  );
  return coverageTypeCodes
    .filter(
      (networkType: string) =>
        networkType !== 'undefined' &&
        networkType !== '' &&
        networkType !== null
    )
    ?.join('%3B');
};

//Utility function to get the BH coverage type codes for getNetworkIdsForPES
const getNetworkIdsForBh = (currentMember: any, featureFlags) => {
  const isSuppressPreEffectiveFF: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.IS_SUPPRESS_PREEFFECTIVE
  );
  const todayDate = DateTime.getCurrentDateTimeWithCentralOffset()
    .toISOString()
    .slice(0, 10);
  const coverageTypeCodeMap = {
    [ReverseCoverageTypesCodes[CoverageTypesCodes.BEHAVIORAL_HEALTH]]:
      Constants.NETWORK_COVERAGE_CODE_PES.BEHAVIORAL_B,
    [ReverseCoverageTypesCodes[CoverageTypesCodes.BEHAVIORAL_CARVE_OUT]]:
      currentMember.bhReciprocityId,
  };
  const { lineOfBusiness } = currentMember;
  const isCurrentMemberPreEffective = currentMember?.isPreEffective === true;
  const isPreEffective =
    !isSuppressPreEffectiveFF && isCurrentMemberPreEffective;
  const coverageTypeCodes = currentMember?.eligibility
    .flatMap((coverage) => coverage?.memberHealthCoverage?.coverageType)
    .filter((covType) =>
      isPreEffective
        ? true
        : covType?.coverageEffDateRange?.endDate >= todayDate &&
          covType?.coverageEffDateRange?.startDate <= todayDate
    )
    .map((covType) => {
      const isLoBMNR = lineOfBusiness === LOB.MNR;
      const isLobCNS = lineOfBusiness === LOB.CNS;
      const isLobIFP = lineOfBusiness === LOB.IFP;
      const isCovTypeBh =
        covType?.typeCode?.coverageTypeCode ===
        ReverseCoverageTypesCodes[CoverageTypesCodes.BEHAVIORAL_HEALTH];
      const covTypeReciprocityId = covType?.typeCode?.reciprocityId;
      const shouldReturnReciprocityId =
        (isLoBMNR || isLobCNS || isLobIFP) && isCovTypeBh;
      /** DE963347 fix - in getNetworkIdsForBh, if coverageType is B and LOB is MNR or CNS, then
       * return reciprocityId from coverageTypeCode of BH coverage. else default is coverageTypeCodeMap that's constant.
       */
      return shouldReturnReciprocityId
        ? covTypeReciprocityId
        : coverageTypeCodeMap[covType?.typeCode?.coverageTypeCode] || '';
    });
  return coverageTypeCodes
    .filter((networkType: string) => networkType)
    ?.join(',');
};

//utility function to get network id for dental codes for getNetworkIdsForPES
const getNetworkIdsForDental = (currentMember) => {
  const networks =
    currentMember?.networks?.filter((coverage) =>
      ReverseDentalCodes.includes(ReverseCoverageTypesCodes[coverage?.type])
    ) || [];
  const networksReciprocityId = networks[0]?.value[0];
  const currentMemberReciprocityId = currentMember?.reciprocityId;
  return networksReciprocityId || currentMemberReciprocityId;
};

//utility function to get network id for vision codes for getNetworkIdsForPES
const getNetworkIdsForVision = (currentMember, featureFlags) => {
  const visionChipEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.VISION_CHIP_ENABLED
  );
  if (visionChipEnabled) {
    const networks =
      currentMember?.networks?.filter((coverage) =>
        ReverseVisionCodes.includes(ReverseCoverageTypesCodes[coverage?.type])
      ) || [];
    return networks[0]?.value[0] || currentMember?.reciprocityId;
  }
  return '0';
};

const getNetworkIdForGuestUser = () => {
  const guestUser = storage.session.get(
    Constants.STORAGE_KEYS.SESSION.GUEST_USER
  );
  return guestUser?.eligibility[0].memberHealthCoverage.coverageType[0]
    .reciprocityId;
};
export const getNetworkIdsForPES = (
  currentMember: any,
  coverageType: string,
  featureFlags: { key: string; active: boolean }[]
) => {
  const isGuest = storage.session.get(Constants.STORAGE_KEYS.SESSION.IS_GUEST);
  if (isGuest) {
    return getNetworkIdForGuestUser();
  }
  if (coverageType === 'B') {
    return getNetworkIdsForBh(currentMember, featureFlags);
  }

  if (ReverseDentalCodes.includes(coverageType)) {
    return getNetworkIdsForDental(currentMember);
  }

  if (ReverseVisionCodes.includes(coverageType)) {
    return getNetworkIdsForVision(currentMember, featureFlags);
  }

  return currentMember?.reciprocityId;
};

export const getOnlineRetailers = (isOnlineProviders: any) => {
  if (typeof isOnlineProviders === 'boolean') return isOnlineProviders;
  if (isOnlineProviders === null) return null;
  if (isOnlineProviders === 'Y') return true;
  if (isOnlineProviders === 'N') return false;
  if (isOnlineProviders?.toLowerCase() === 'blank') return null;
  return undefined;
};

export const isMemberEAPEligible = () =>
  storage.session.get(Constants.STORAGE_KEYS.SESSION.IS_BH_EAP_ELIGIBLE) ||
  false;

export const getIsCustomPreferredPolicy = (
  customPreferredPolicies: CustomPreferredPolicy[],
  policyId: string,
  reciprocityId: string
): boolean =>
  !!customPreferredPolicies?.find(
    (cpp) =>
      removeLeadingZeroes(cpp.policyId) === removeLeadingZeroes(policyId) &&
      cpp.reciprocityId === reciprocityId
  );

export const getMemberShipCategory = (
  currentMember: any,
  featureFlags: { key: string; active: boolean }[]
) => {
  const uspToggleFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_ENABLE
  );
  const memCatergory = currentMember?.membershipCategory || '';
  return uspToggleFlag ? memCatergory : null;
};

export const getIsOxfordAndUSP = (
  currentMember,
  coverageType: string,
  featureFlags: { key: string; active: boolean }[]
) => {
  const { claimSystemTypeCode, eligibilitySystemTypeCode } =
    getClaimOrEligibilitySystemTypeCode(currentMember, coverageType);
  const membershipCategory = getMemberShipCategory(currentMember, featureFlags);

  let isUSPMember: boolean = false;
  let isOXMember: boolean = false;
  if (
    eligibilitySystemTypeCode?.toUpperCase() === 'CR' &&
    claimSystemTypeCode?.toUpperCase() === 'CR'
  ) {
    isUSPMember = true;

    if (membershipCategory?.toUpperCase() === 'OXFORD') {
      isOXMember = true;
    }
  }

  return {
    isUSPMember,
    isOXMember,
  };
};

export const isNotMNRMember = (population) =>
  population !== PopulationType.COSMOS;

export const isMNRMember = (lineOfBusiness) => lineOfBusiness === LOB.MNR;
export const isCNSMember = (lineOfBusiness) => lineOfBusiness === LOB.CNS;

export const isMnrTieredPlan = (tierBenefits, rulesPackageKey) => {
  const hpbpCodes = tierBenefits?.map(({ hpbpCode }) => hpbpCode);
  return !!hpbpCodes?.includes(rulesPackageKey);
};

export const validateMnrTieredBenefitEligibility = (
  currentMember,
  tierBenefits,
  providerType
): boolean => {
  const { rulesPackageKey = '' } = currentMember || {};
  return !!tierBenefits?.find(
    ({ hpbpCode, providerType: eligibleProviderType }) =>
      hpbpCode === rulesPackageKey &&
      eligibleProviderType
        ?.toLowerCase()
        ?.replaceAll('_', '')
        ?.split(',')
        ?.includes(
          providerType?.toLowerCase()?.replaceAll(' ', '')?.replaceAll('_', '')
        )
  );
};

export const getRulesPackageKey = (
  currentMember,
  tierBenefits,
  providerType,
  uspTierOneFlag,
  enableTier1FromMemberRPK = false
) => {
  let rulesPackageKey = (uspTierOneFlag || enableTier1FromMemberRPK)
    ? currentMember?.rulesPackageKey || ''
    : null;
  if (isMNRMember(currentMember?.lineOfBusiness)) {
    rulesPackageKey = validateMnrTieredBenefitEligibility(
      currentMember,
      tierBenefits,
      providerType
    )
      ? rulesPackageKey
      : null;
  }
  return rulesPackageKey;
};

export const isTier1Plan = (
  enableTier1RulesPackageFF: boolean,
  memberRulesPackageKey: string,
  isTiredPlanVal: boolean | undefined
): boolean => {

  const shouldEnableTier1 = enableTier1RulesPackageFF && !isEmpty(memberRulesPackageKey);
  if (shouldEnableTier1) {
    return true;
  }
  if (isTiredPlanVal !== undefined) {
    return isTiredPlanVal;
  }
  return false;
};

export const modifyRulesPackageKey = (memberInfo, tierBenefits) => {
  const currentMember = memberInfo?.[0] || {};
  const { lineOfBusiness } = currentMember;

  if (isMNRMember(lineOfBusiness)) {
    const { contractNumber, pbpNumber, segmentId } =
      currentMember?.eligibility?.[0]?.memberHealthCoverage
        ?.coverageType?.[0] || {};
    const rulesPackageKey = `${contractNumber}-${pbpNumber}-${segmentId}`;
    return memberInfo?.map((member) => {
      member.rulesPackageKey = isMnrTieredPlan(tierBenefits, rulesPackageKey)
        ? rulesPackageKey
        : '';
      return member;
    });
  }
  return memberInfo;
};

export const isOHCNSMember = (OHpolicyIds: string[] = []) => {
  const { policyNumber } = getCurrentMember();
  return OHpolicyIds.includes(policyNumber);
};

export const getProviderTypeShortName = (
  providerType: ProviderTypes
): ProviderTypeShortNames | undefined => {
  switch (providerType?.toLowerCase()) {
    case ProviderTypes.ORGANIZATION.toLowerCase():
      return ProviderTypeShortNames.ORGANIZATION;
    case ProviderTypes.PRACTITIONER.toLowerCase():
      return ProviderTypeShortNames.PRACTITIONER;
    case ProviderTypes.GROUP.toLowerCase():
      return ProviderTypeShortNames.PROVIDER_GROUP;
    case ProviderTypes.MEDICAL_GROUP.toLowerCase():
      return ProviderTypeShortNames.MEDICAL_GROUP;
    case ProviderTypes.MEDICALGROUP.toLowerCase():
      return ProviderTypeShortNames.MEDICAL_GROUP;
    default:
      return undefined;
  }
};

export const getAdditionalAttributes = (
  additionalAttributes,
  currentMember,
  coverageType,
  providerType,
  apiName
) => {
  const { policyNumber } = currentMember;
  const matchingAttributes = additionalAttributes?.filter(
    (attribute) =>
      attribute.policyIds?.includes(policyNumber) &&
      attribute.coverage?.includes(coverageType) &&
      attribute.providerType?.includes(
        getProviderTypeShortName(providerType?.toLowerCase())
      ) &&
      attribute.gqlApi?.includes(apiName) &&
      attribute.active
  );
  return matchingAttributes?.map((item) => item?.dntAttributeName);
};

export const isDentalNetworkIdNotAvailable = (
  selectedChipValue,
  currentMember,
  coverageType,
  showDentalChip
): boolean => {
  const dentalNetworkId =
    currentMember?.networks?.find(
      (coverage) =>
        ReverseCoverageTypesCodes[coverage?.type] ===
          ReverseCoverageTypesCodes.DENTAL ||
        ReverseCoverageTypesCodes[coverage?.type] ===
          ReverseCoverageTypesCodes.DENTALEMBEDDED
    )?.value?.[0] || '';

  return (
    coverageType === ReverseCoverageTypesCodes.DENTAL &&
    selectedChipValue === CareCategories.DENTAL &&
    !dentalNetworkId &&
    showDentalChip
  );
};

export const getDomainForAutoComplete = (currentMember) => {
  const memberCoverageType =
    currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType;
  if (memberCoverageType?.length === 1) {
    const memberCoverageTypeCode =
      memberCoverageType[0]?.typeCode?.coverageTypeCode;

    return memberCoverageTypeCode === 'OBH' || memberCoverageTypeCode === 'B'
      ? 'behavioral'
      : undefined;
  }

  return undefined;
};

export const getMemberHasDesignatedNetwork = (coverage, network: string) =>
  !!coverage?.designatedNetwork?.includes(network);

export const getNetworkIdForDentalVision = (currentMember, codes) => {
  const networks =
    currentMember?.networks?.filter((coverage) =>
      codes.includes(ReverseCoverageTypesCodes[coverage?.type])
    ) || [];
  return networks[0]?.value[0];
};
